import { useEffect } from 'react';

import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

export const useIubendaScript: VoidFunction = () => {
  const isMobileAppWebView = useIsMobileAppWebView();
  useEffect(() => {
    if (!isMobileAppWebView) {
      const scriptConfig = `var _iub = _iub || [];
      _iub.csConfiguration = {
        googleConsentMode: 'template',
        askConsentAtCookiePolicyUpdate: true,
        cookiePolicyInOtherWindow: true,
        floatingPreferencesButtonCaptionColor: '#F5EEEE',
        floatingPreferencesButtonColor: '#E4E0E05E',
        floatingPreferencesButtonDisplay: 'anchored-center-right',
        perPurposeConsent: true,
        siteId: 3462162,
        whitelabel: false,
        cookiePolicyId: 33184857,
        lang: 'it',
        cookiePolicyUrl: 'https://pb-attachments.s.welmed.it/privacy/welmed_privacy_policy.pdf',
        banner: {
          acceptButtonCaptionColor: '#FFFFFF',
          acceptButtonColor: '#0073CE',
          acceptButtonDisplay: true,
          backgroundColor: '#FFFFFF',
          closeButtonRejects: true,
          customizeButtonCaptionColor: '#4D4D4D',
          customizeButtonColor: '#DADADA',
          customizeButtonDisplay: true,
          explicitWithdrawal: true,
          listPurposes: true,
          position: 'float-bottom-center',
          showTitle: false,
          textColor: '#000000',
        },
      };`;

      const script = document.createElement('script');
      script.appendChild(document.createTextNode(scriptConfig));

      const iubendaCdn = document.createElement('script');
      iubendaCdn.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
      iubendaCdn.async = true;

      const iubendaSrc = document.createElement('script');
      iubendaSrc.setAttribute('src', 'https://cs.iubenda.com/autoblocking/3462162.js');

      document.head.prepend(script, iubendaSrc, iubendaCdn);
    }
  }, [isMobileAppWebView]);
};

import { makeVar, useReactiveVar } from '@apollo/client';

import { IExtractParametersFile } from '../AiProcessingModals/AiParamsDataExtractionModals/ExtractParametersModal/types';

interface IUseUploadFilesActions {
  openAiProcessingModals: VoidFunction;
  openAiParamsDataExtractionModals: VoidFunction;
  setAindoFile: (aindoFile: IExtractParametersFile) => void;
  reset: VoidFunction;
}

interface IUseUploadFilesStoreState {
  isAiProcessingModalsVisible: boolean;
  isAiParamsDataExtractionModalsVisible: boolean;
  // TODO: (NP-5223) Support multiple files
  aindoFile?: IExtractParametersFile;
}

type UseUploadFilesStore = () => IUseUploadFilesActions & IUseUploadFilesStoreState;

const isAiProcessingModalsVisibleVar = makeVar<IUseUploadFilesStoreState['isAiProcessingModalsVisible']>(false);
const isAiParamsDataExtractionModalsVisibleVar =
  makeVar<IUseUploadFilesStoreState['isAiParamsDataExtractionModalsVisible']>(false);
const aindoFileVar = makeVar<IUseUploadFilesStoreState['aindoFile']>(undefined);

const openAiProcessingModals: IUseUploadFilesActions['openAiProcessingModals'] = () =>
  isAiProcessingModalsVisibleVar(true);

const openAiParamsDataExtractionModals: IUseUploadFilesActions['openAiParamsDataExtractionModals'] = () =>
  isAiParamsDataExtractionModalsVisibleVar(true);

const setAindoFile: IUseUploadFilesActions['setAindoFile'] = aindoFile => aindoFileVar(aindoFile);

const reset: IUseUploadFilesActions['reset'] = () => {
  isAiProcessingModalsVisibleVar(false);
  isAiParamsDataExtractionModalsVisibleVar(false);
  aindoFileVar(undefined);
};

export const useUploadFilesStore: UseUploadFilesStore = () => {
  const isAiProcessingModalsVisible = useReactiveVar(isAiProcessingModalsVisibleVar);
  const isAiParamsDataExtractionModalsVisible = useReactiveVar(isAiParamsDataExtractionModalsVisibleVar);
  const aindoFile = useReactiveVar(aindoFileVar);

  return {
    isAiProcessingModalsVisible,
    isAiParamsDataExtractionModalsVisible,
    aindoFile,
    openAiProcessingModals,
    openAiParamsDataExtractionModals,
    setAindoFile,
    reset,
  };
};

import { getFileTags } from '@lib/features-ui';
import { useDispatch, useSelector } from 'react-redux';

import { FileTag, Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { removeAttachmentFromList, updateAttachmentInList } from 'store/attachments/actionCreators';
import { YesNo } from 'store/attachments/enums';
import { getAttachmentsToUpload } from 'store/attachments/selectors';

interface IUseUploadFileItemStateProps {
  index: number;
}

export interface IuseUploadFileItemStateReturn {
  commitFileName: (value: string) => void;
  commitFileTag: (value: FileTag) => void;
  commitFileOwnershipDecision: (value: YesNo) => void;
  commitFileDateOfExecution: (value: Maybe<Date>) => void;
  commitIsFilePrivate: (value: boolean) => void;
  removeFile: VoidFunction;
}

type useUploadFileItemState = (params: IUseUploadFileItemStateProps) => IuseUploadFileItemStateReturn;

export const useUploadFileItemState: useUploadFileItemState = ({ index }) => {
  const { isPatient } = useActiveProfileContext();
  const dispatch = useDispatch();
  const attachmentsToUpload = useSelector(getAttachmentsToUpload);

  const commitFileName: IuseUploadFileItemStateReturn['commitFileName'] = value => {
    if (!attachmentsToUpload) return;
    dispatch(
      updateAttachmentInList({
        attachmentToUpdateIndex: index,
        newParameters: {
          label: value,
        },
      })
    );
  };

  const commitFileDateOfExecution: IuseUploadFileItemStateReturn['commitFileDateOfExecution'] = value => {
    if (!attachmentsToUpload) return;
    dispatch(
      updateAttachmentInList({
        attachmentToUpdateIndex: index,
        newParameters: {
          dateOfExecution: value,
        },
      })
    );
  };

  const commitFileTag: IuseUploadFileItemStateReturn['commitFileTag'] = value => {
    const currentTag = getFileTags({ isPatient }).find((tag): boolean => tag.value === value);
    if (!attachmentsToUpload || !currentTag) return;

    dispatch(
      updateAttachmentInList({
        attachmentToUpdateIndex: index,
        newParameters: {
          fileTag: currentTag.value,
        },
      })
    );
  };

  const commitIsFilePrivate: IuseUploadFileItemStateReturn['commitIsFilePrivate'] = value => {
    if (!attachmentsToUpload) return;
    dispatch(
      updateAttachmentInList({
        attachmentToUpdateIndex: index,
        newParameters: {
          isFilePrivate: value,
        },
      })
    );
  };

  const commitFileOwnershipDecision: IuseUploadFileItemStateReturn['commitFileOwnershipDecision'] = value => {
    if (!attachmentsToUpload) return;

    dispatch(
      updateAttachmentInList({
        attachmentToUpdateIndex: index,
        newParameters: {
          patientOwnershipDecision: value,
        },
      })
    );
  };

  const removeFile: IuseUploadFileItemStateReturn['removeFile'] = () => {
    if (!attachmentsToUpload) return;
    dispatch(removeAttachmentFromList(index));
  };

  return {
    commitFileName,
    commitFileDateOfExecution,
    commitFileTag,
    commitIsFilePrivate,
    commitFileOwnershipDecision,
    removeFile,
  };
};

import validator from 'validator';

export const validateDate = (date: string | null | undefined, key: string): void => {
  if (date !== '' && !date) {
    return;
  }

  if (!validator.isISO8601(date)) {
    throw new Error(`Date validation error on ${key}`);
  }
};

import { AiParamsDataExtractionModals } from './AiParamsDataExtractionModals';

import { Maybe } from '__generated__/types';
import { attachmentsModel } from 'features/Attachments/model';

interface IAiProcessingModalsProps {
  isAiParamsDataExtractionModalsVisible?: boolean;
  patientId?: Maybe<string>;
  onAiParamsFinish: VoidFunction;
}

export const AiProcessingModals = ({
  isAiParamsDataExtractionModalsVisible,
  patientId,
  onAiParamsFinish,
}: IAiProcessingModalsProps): JSX.Element => {
  attachmentsModel.useAiParamsExtractionSubscription(onAiParamsFinish);

  return (
    <>
      {isAiParamsDataExtractionModalsVisible && (
        <AiParamsDataExtractionModals patientId={patientId} onCancel={onAiParamsFinish} />
      )}
    </>
  );
};

import { InputFile, useBreakpoints } from '@lib/react-components';
import { Box, Button, Stack } from '@mui/material';
import { ChangeEventHandler, forwardRef, useState } from 'react';

import { MobileFileActionsMenu } from '../../MobileFileActionsMenu';

import { useIsSaveButtonDisabled } from './hooks/useIsSaveButtonDisabled';

import { i18n } from 'i18n';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IFileUploadModalFooterProps {
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
  saveChanges?: VoidFunction;
  onCancel: VoidFunction;
}

export const FileUploadModalFooter = forwardRef<HTMLDivElement, IFileUploadModalFooterProps>(
  ({ handleFileChange, saveChanges, onCancel }, ref): JSX.Element => {
    const { isMobileView } = useBreakpoints();
    const isMobileAppWebView = useIsMobileAppWebView();
    const [showMobileActionsMenu, setShowMobileActionsMenu] = useState(false);
    const isSaveButtonDisabled = useIsSaveButtonDisabled();

    return (
      <>
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', mobile: 'row' },
            alignItems: { xs: 'flex-start', mobile: 'center' },
            justifyContent: 'space-between',
          }}
        >
          <Stack
            sx={{
              flexDirection: { xs: 'column', mobile: 'row' },
              gap: '14px',
              ...(isMobileView && {
                width: '100%',
                marginTop: '24px',
              }),
            }}
          >
            <Button disabled={isSaveButtonDisabled} onClick={(): void => saveChanges?.()}>
              {i18n.t('general.buttons.upload')}
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              {i18n.t('general.buttons.cancel')}
            </Button>
            <Box ref={ref} />
          </Stack>
          {isMobileAppWebView ? (
            <Button onClick={(): void => setShowMobileActionsMenu(true)} variant="link">
              {i18n.t('components.uploadFiles.addMoreFilesInputText')}
            </Button>
          ) : (
            <InputFile variant="link" isMultiple onChange={handleFileChange}>
              {i18n.t('components.uploadFiles.addMoreFilesInputText')}
            </InputFile>
          )}
        </Stack>
        {showMobileActionsMenu && (
          <MobileFileActionsMenu
            onClose={(): void => {
              setShowMobileActionsMenu(false);
            }}
          />
        )}
      </>
    );
  }
);

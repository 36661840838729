import { attachmentsApi } from '../api';
import { RestoreFileHookResult } from '../api/graphql/__generated__/RestoreFile.mutation';

import { i18n } from 'i18n';

export const useRestoreFile = (): RestoreFileHookResult[0] => {
  const [restoreFileMutation] = attachmentsApi.useRestoreFile({
    notifications: {
      onCompleted: i18n.t('components.fileActions.restore.successNotification') as string,
    },
  });

  return restoreFileMutation;
};

import { IPopoverItem } from '@lib/react-components';

import { CHAT_MENU_ITEMS_CONFIG, ChatMenuItemType, getChatMenuItemPosition } from '../config';

interface IUseChatModelMenuItemsParams {
  actions: Partial<Record<ChatMenuItemType, VoidFunction>>;
}

interface IUseChatModelMenuItemsReturn {
  items: IPopoverItem[];
}

type UseChatModelMenuItems = (params: IUseChatModelMenuItemsParams) => IUseChatModelMenuItemsReturn;

export const useChatModelMenuItems: UseChatModelMenuItems = ({ actions }) => ({
  items: (Object.keys(actions) as ChatMenuItemType[])
    .sort((a, b) => getChatMenuItemPosition(a) - getChatMenuItemPosition(b))
    .map(key => ({
      title: CHAT_MENU_ITEMS_CONFIG[key as ChatMenuItemType],
      onClickHandler: actions[key as ChatMenuItemType],
    })),
});

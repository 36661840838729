import { BREAK_POINTS } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 25,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  singleLine: {
    gridColumn: '1 / span 2',
  },

  icon: {
    '&.MuiSelect-icon': {
      right: '8px',
      top: 'calc(50% - 11px)',
    },
  },
}));

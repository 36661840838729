import {
  ActiveProfileContext,
  IActiveDoctor,
  IActiveNonDoctor,
  IActivePatient,
  IActiveProfile,
  SetActiveProfileHandler,
  SetProfileListHandler,
} from '@lib/core';
import { DeepPartial } from '@lib/utils';
import { useContext } from 'react';

import { Maybe, ProfileType } from '__generated__/types';

type UseActiveProfileReturn = {
  activeProfile: IActiveProfile | null;
  setActiveProfile: SetActiveProfileHandler;
  profileList: IActiveProfile[];
  setProfileList: SetProfileListHandler;
  isIncompleteDoctorProfile: boolean;
  isPatient: boolean;
  isDoctor: boolean;
  isNonDoctor: boolean;
  isAnonymous: boolean;
  activeProfileType: Maybe<ProfileType>;
  activeDoctor?: Maybe<IActiveDoctor>;
  activePatient?: Maybe<IActivePatient>;
  activeNonDoctor?: Maybe<IActiveNonDoctor>;
  updateDoctor: (updatedDoctor: DeepPartial<IActiveDoctor>) => void;
  updateNonDoctor: (updatedNonDoctor: DeepPartial<IActiveNonDoctor>) => void;
  updatePatient: (updatedPatient: DeepPartial<IActivePatient>) => void;
};

export const useActiveProfileContext = (): UseActiveProfileReturn => {
  const { activeProfile, setActiveProfile, setProfileList, profileList } = useContext(ActiveProfileContext);

  const isAnonymous = !activeProfile;
  const isPatient = activeProfile?.profileType === ProfileType.PATIENT;
  const isDoctor = activeProfile?.profileType === ProfileType.DOCTOR;
  const isNonDoctor = activeProfile?.profileType === ProfileType.NON_DOCTOR;

  const isIncompleteDoctorProfile = activeProfile ? isDoctor && !activeProfile.doctor : false;
  const activeProfileType = activeProfile?.profileType;

  const activeDoctor = activeProfile?.doctor;
  const activePatient = activeProfile?.patient;
  const activeNonDoctor = activeProfile?.nonDoctor;

  const updatePatient = (updatedPatient: DeepPartial<IActivePatient>): void => {
    const newProfileList = profileList.map(
      profile => ({ ...profile, patient: { ...profile.patient, ...updatedPatient } }) as IActiveProfile
    );
    setProfileList(newProfileList);

    if (isPatient) {
      const newPatient = newProfileList.find(({ profileType }: IActiveProfile) => profileType === ProfileType.PATIENT);

      if (newPatient) {
        setActiveProfile(newPatient);
      }
    }
  };

  const updateDoctor = (updatedDoctor: DeepPartial<IActiveDoctor>): void => {
    const newProfileList = profileList.map(
      profile => ({ ...profile, doctor: { ...profile.doctor, ...updatedDoctor } }) as IActiveProfile
    );
    setProfileList(newProfileList);

    if (isDoctor) {
      const newDoctor = newProfileList.find(({ profileType }: IActiveProfile) => profileType === ProfileType.DOCTOR);

      if (newDoctor) {
        setActiveProfile(newDoctor);
      }
    }
  };

  const updateNonDoctor = (updatedNonDoctor: DeepPartial<IActiveNonDoctor>): void => {
    const newProfileList = profileList.map(
      profile => ({ ...profile, nonDoctor: { ...profile.nonDoctor, ...updatedNonDoctor } }) as IActiveProfile
    );
    setProfileList(newProfileList);

    if (isNonDoctor) {
      const newNonDoctor = newProfileList.find(
        ({ profileType }: IActiveProfile) => profileType === ProfileType.NON_DOCTOR
      );

      if (newNonDoctor) {
        setActiveProfile(newNonDoctor);
      }
    }
  };

  return {
    activeProfile,
    activeProfileType: activeProfileType ?? null,
    setActiveProfile,
    profileList,
    setProfileList,
    isIncompleteDoctorProfile,
    isPatient,
    isDoctor,
    isNonDoctor,
    isAnonymous,
    activeDoctor,
    activePatient,
    activeNonDoctor,
    updatePatient,
    updateDoctor,
    updateNonDoctor,
  };
};

import { CurrentUserContext } from '@lib/core';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SecureDatabase } from './MedicalDB';

import { FileTag, Maybe } from '__generated__/types';

export interface Draft {
  patientId: string;
  text: string;
  createdAt: Date;
  tag?: FileTag.DICOM_REPORT | FileTag.REPORT;
}

interface MedicalDBContextType {
  drafts: Record<string, Draft>;
  getDraft: (patientId: string) => Promise<Maybe<Draft>>;
  saveDraft: (draft: Draft) => Promise<void>;
  loadDrafts: (patientId: string) => Promise<void>;
  deleteDraft: (patientId: string) => Promise<void>;
}

const MedicalDBContext = createContext<MedicalDBContextType>({
  drafts: {},
  getDraft: async () => null,
  saveDraft: async () => {},
  loadDrafts: async () => {},
  deleteDraft: async () => {},
});

interface IMedicalDBProviderProps {
  children: ReactNode;
}

export const MedicalDBProvider = ({ children }: IMedicalDBProviderProps): JSX.Element => {
  const [drafts, setDrafts] = useState<Record<string, Draft>>({});

  let draftsTable: SecureDatabase | undefined;

  const { currentUser } = useContext(CurrentUserContext);

  const getDraft = useCallback(
    async (patientId: string): Promise<Maybe<Draft>> => {
      const draft = await draftsTable?.get(patientId);

      return draft
        ? {
            patientId: draft.patientId,
            text: draft.text,
            createdAt: draft.createdAt,
            tag: draft.tag,
          }
        : null;
    },
    [draftsTable]
  );

  const loadDrafts = useCallback(async (): Promise<void> => {
    const allDrafts = await draftsTable?.toArray();

    const draftsMap: Record<string, Draft> = {};
    allDrafts?.forEach(draft => {
      draftsMap[draft.patientId] = {
        patientId: draft.patientId,
        text: draft.text,
        createdAt: draft.createdAt,
        tag: draft.tag,
      };
    });
    setDrafts(draftsMap);
  }, [draftsTable]);

  const saveDraft = useCallback(
    async (draft: Draft): Promise<void> => {
      try {
        await draftsTable?.put(draft);
      } catch (error) {
        console.error(`Errore durante il salvataggio della bozza:`, error);
      }
    },
    [draftsTable]
  );

  const deleteDraft = useCallback(
    async (patientId: string): Promise<void> => {
      try {
        await draftsTable?.delete(patientId);
      } catch (error) {
        console.error(`Errore durante la cancellazione della bozza:`, error);
      }
    },
    [draftsTable]
  );

  useEffect(() => {
    if (currentUser && currentUser.salt && currentUser.id) {
      draftsTable = new SecureDatabase(currentUser.id, currentUser.salt, currentUser.id);
      loadDrafts();
    }
  }, [currentUser]);

  const value = useMemo(() => ({ drafts, saveDraft, loadDrafts, deleteDraft, getDraft }), [drafts]);

  return <MedicalDBContext.Provider value={value}>{children}</MedicalDBContext.Provider>;
};

export const useDrafts = (): MedicalDBContextType => useContext(MedicalDBContext);

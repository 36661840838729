import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type CreatePatientProfileForUserVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  institutionId: Types.Scalars['String']['input'];
  isConsentReceived: Types.Scalars['Boolean']['input'];
}>;


export type CreatePatientProfileForUser = { __typename?: 'Mutation', createPatientProfileForUser: { __typename?: 'Patient', id: string, portalUser: { __typename?: 'PortalUserModel', id: string } } };


export const CreatePatientProfileForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePatientProfileForUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"institutionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isConsentReceived"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPatientProfileForUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"institutionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"institutionId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isConsentReceived"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isConsentReceived"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"portalUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreatePatientProfileForUserMutationFn = Apollo.MutationFunction<CreatePatientProfileForUser, CreatePatientProfileForUserVariables>;

/**
 * __useCreatePatientProfileForUser__
 *
 * To run a mutation, you first call `useCreatePatientProfileForUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientProfileForUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientProfileForUser, { data, loading, error }] = useCreatePatientProfileForUser({
 *   variables: {
 *      userId: // value for 'userId'
 *      institutionId: // value for 'institutionId'
 *      isConsentReceived: // value for 'isConsentReceived'
 *   },
 * });
 */
export function useCreatePatientProfileForUser(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatientProfileForUser, CreatePatientProfileForUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePatientProfileForUser, CreatePatientProfileForUserVariables>(CreatePatientProfileForUserDocument, options);
      }
export type CreatePatientProfileForUserHookResult = ReturnType<typeof useCreatePatientProfileForUser>;
export type CreatePatientProfileForUserMutationResult = Apollo.MutationResult<CreatePatientProfileForUser>;
export type CreatePatientProfileForUserMutationOptions = Apollo.BaseMutationOptions<CreatePatientProfileForUser, CreatePatientProfileForUserVariables>;
import { useSelector } from 'react-redux';

import { useResetFileList } from './useResetFileList';
import { useUploadFilesStore } from './useUploadFilesStore';

import { useAindoFlowAvailability } from 'features/Attachments/components/AttachmentsList/AttachmentItem/hooks';
import { getAttachmentsToUpload } from 'store/attachments/selectors';

export const useAfterUploadFilesAction = (): VoidFunction => {
  const { openAiParamsDataExtractionModals } = useUploadFilesStore();
  const attachmentsToUpload = useSelector(getAttachmentsToUpload);
  const { getAindoAvailabilityByFile } = useAindoFlowAvailability();
  const { resetFileList } = useResetFileList();

  return () => {
    if (attachmentsToUpload?.some(getAindoAvailabilityByFile)) {
      openAiParamsDataExtractionModals();
    } else {
      resetFileList();
    }
  };
};

import { CurrentUserContext } from '@lib/core';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans } from 'react-i18next';

import { useRootApiCallEmailVerificationFlow } from '../api';
import { useRootModelSubmitVerifyEmail } from '../model';

import { useStyles as useVerifyUserStyles, VerifyUser } from 'components/VerifyUser';
import { i18n } from 'i18n';
import { prepareHiddenEmail } from 'lib/helpers/parsing';

export const RootVerifyUserEmail = (): JSX.Element => {
  const { currentUser } = useContext(CurrentUserContext);
  const { classes: verifyUserClasses } = useVerifyUserStyles();
  const [callEmailVerification] = useRootApiCallEmailVerificationFlow();
  const { loading: isVerificationSubmitting, submitVerifyEmailHandler } = useRootModelSubmitVerifyEmail();

  const hiddenEmail = prepareHiddenEmail(currentUser?.email || '');

  const callVerifyEmailHandler = async (): Promise<void> => {
    await callEmailVerification();
  };

  return (
    <VerifyUser
      notificationText={i18n.t('notifications.verifyEmail')}
      modalProps={{
        title: i18n.t('components.forms.verificationForms.verifyEmail.title'),
        sendText: (
          <Typography className={verifyUserClasses.formText}>
            <Trans
              i18n={i18n}
              i18nKey="components.forms.verificationForms.verifyEmail.subtitle"
              values={{ email: hiddenEmail }}
            />
          </Typography>
        ),
        resendText: (
          <Typography className={verifyUserClasses.formText}>
            <Trans
              i18n={i18n}
              i18nKey="components.forms.verificationForms.verifyEmail.subtitleSent"
              values={{ email: hiddenEmail }}
            />
          </Typography>
        ),
        isVerificationSubmitting,
        callVerificationHandler: callVerifyEmailHandler,
        submitVerificationHandler: submitVerifyEmailHandler,
      }}
    />
  );
};

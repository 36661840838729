import { attachmentsApi } from '../api';
import { GenerateAndSavePdfPatientFile } from '../api/graphql/__generated__/GenerateAndSavePdfPatientFile.mutation';

import { GenerateAndStorePdfPatientFileFileType } from '__generated__/types';

interface IUseGenerateAndSavePdfPatientFileParams {
  variables: {
    patientId: string;
    fileType: GenerateAndStorePdfPatientFileFileType;
    title: string;
    otpCode: string;
    subjects: string[];
  };
  onError?: VoidFunction;
  onCompleted?: (data: GenerateAndSavePdfPatientFile) => void;
}

type UseGenerateAndSavePdfPatientFileReturn = (params: IUseGenerateAndSavePdfPatientFileParams) => Promise<void>;

export const useGenerateAndSavePdfPatientFile = (): UseGenerateAndSavePdfPatientFileReturn => {
  const [generateAndSavePdfPatientFileMutation] = attachmentsApi.useGenerateAndSavePdfPatientFile();

  const generateAndSavePdfPatientFile: UseGenerateAndSavePdfPatientFileReturn = async ({
    onCompleted,
    onError,
    variables,
  }) => {
    await generateAndSavePdfPatientFileMutation({ variables, onCompleted, onError });
  };

  return generateAndSavePdfPatientFile;
};

import { BREAK_POINTS, zIndexValues } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  shadow: {
    zIndex: zIndexValues.defaultValue + 1,
  },
  contentBox: {
    maxHeight: '500px',
    marginTop: '20px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      overflow: 'initial',
      maxHeight: 'unset',
    },
  },
  content: {
    gap: 'unset',
    height: 'unset',
  },
  paper: {
    [theme.breakpoints.up(BREAK_POINTS.MOBILE)]: {
      height: 'unset',
    },
  },
}));

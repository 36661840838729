import { CurrentUserContext } from '@lib/core';
import { useGetPatient } from '@lib/features-bll';
import { getUserFullName } from '@lib/utils';
import { useContext } from 'react';

import { Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';

interface IUsePatientPortalUserIdReturn {
  patientPortalUserId?: string;
  patientFullName: string;
  loading: boolean;
}

type UsePatientData = (patientId?: Maybe<string>) => IUsePatientPortalUserIdReturn;

/**
 * Returns processed patient data
 * @param patientId
 * @returns patient data
 */
export const usePatientData: UsePatientData = patientId => {
  const { currentUser } = useContext(CurrentUserContext);
  const { activeProfile, isPatient } = useActiveProfileContext();
  const { data, loading } = useGetPatient({
    variables: {
      patientId: patientId || '',
    },
    fetchPolicy: 'cache-first',
    skip: isPatient || !patientId,
  });

  return {
    patientPortalUserId: isPatient ? activeProfile?.portalUser.id : data?.patient.portalUser?.id,
    patientFullName: getUserFullName(isPatient ? currentUser : data?.patient.user),
    loading,
  };
};

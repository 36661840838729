import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { ThreadAttachmentsFile } from '../../../../Requests/api/graphql/__generated__/ThreadAttachmentsFile.fragment';
import { PdfData } from '../../../../../../../mobile/node_modules/@lib/features-bll/src/features/File/graphql/__generated__/PdfData.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type RestoreFileVariables = Types.Exact<{
  file: Types.Scalars['String']['input'];
  patient: Types.Scalars['String']['input'];
}>;


export type RestoreFile = { __typename?: 'Mutation', restoreFile: { __typename?: 'PatientFile', id: string, file: { __typename?: 'File', id: string, type: Types.FileType, tag?: Types.FileTag | null, label: string, uploadedAt: string, dateOfExecution: string, isCancellable: boolean, isModifiable: boolean, cancelledAt?: string | null, hasAnyPdfParametersRevision: boolean, uploaderInstitutionUser?: { __typename?: 'InstitutionUser', id: string, profileType: Types.ProfileType, user: { __typename?: 'User', firstName: string, lastName: string } } | null, pdfData?: { __typename?: 'PDFDataStorage', id: string, fileType: Types.PDFDataStorageType, createdAt: string, isInvalidated: boolean, data: { __typename?: 'PDFData', title: string, subjects: Array<string>, multidisciplinaryTeam?: Array<{ __typename?: 'MultidisciplinaryTeamMember', firstName: string, lastName: string, gender: Types.Gender, specializations: Array<string>, titleCode: Types.UserTitle }> | null, additions?: Array<{ __typename?: 'PDFDataAddition', subjects: Array<string>, addedAt: string, type: Types.PDFDataAdditionType }> | null } } | null, pdfParametersRevision?: { __typename?: 'PdfParametersRevisionModel', completionPercent: number, lastUpdatedAt?: string | null } | null } } };


export const RestoreFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RestoreFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"file"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patient"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restoreFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"file"},"value":{"kind":"Variable","name":{"kind":"Name","value":"file"}}},{"kind":"Argument","name":{"kind":"Name","value":"patient"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patient"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ThreadAttachmentsFile"}}]}}]}}]}},...ThreadAttachmentsFile.definitions,...PdfData.definitions]} as unknown as DocumentNode;
export type RestoreFileMutationFn = Apollo.MutationFunction<RestoreFile, RestoreFileVariables>;

/**
 * __useRestoreFile__
 *
 * To run a mutation, you first call `useRestoreFile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreFile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreFile, { data, loading, error }] = useRestoreFile({
 *   variables: {
 *      file: // value for 'file'
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useRestoreFile(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreFile, RestoreFileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RestoreFile, RestoreFileVariables>(RestoreFileDocument, options);
      }
export type RestoreFileHookResult = ReturnType<typeof useRestoreFile>;
export type RestoreFileMutationResult = Apollo.MutationResult<RestoreFile>;
export type RestoreFileMutationOptions = Apollo.BaseMutationOptions<RestoreFile, RestoreFileVariables>;
import { useContext } from 'react';

import { AttachmentFileModalContext } from '../context';

export const useMultidisciplinaryReportModalControls = (): [boolean | undefined, (isModalOpened: boolean) => void] => {
  const { isMultidisciplinaryReportModalOpened, setMultidisciplinaryReportModalOpened } =
    useContext(AttachmentFileModalContext);

  return [isMultidisciplinaryReportModalOpened, setMultidisciplinaryReportModalOpened];
};

import { CurrentUserContext } from '@lib/core';
import { Modal, ModalSize, useBreakpoints } from '@lib/react-components';
import { Box, Button, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import { useContext } from 'react';
import { Trans } from 'react-i18next';

import { useStyles as useVerifyUserStyles } from '../styles';

import { IVerifyUserModal } from './types';
import { useVerifyUserModal } from './useVerifyUserModal.hook';

import { i18n } from 'i18n';

export const VerifyUserModal = ({
  title,
  sendText,
  resendText,
  setModalOpened,
  callVerificationHandler,
  submitVerificationHandler,
  isVerificationSubmitting,
}: IVerifyUserModal): JSX.Element => {
  const { classes: verifyUserClasses } = useVerifyUserStyles();
  const { isMobileView } = useBreakpoints();
  const { userError } = useContext(CurrentUserContext);
  const { values, getError, isCodeSent, resendCode, handleBlur, handleChange, handleSubmit } = useVerifyUserModal({
    setModalOpened,
    callVerificationHandler,
    submitVerificationHandler,
  });

  const isBtnSubmitDisabled = isVerificationSubmitting || !!getError('verificationCode') || !values.verificationCode;

  return (
    <Modal
      classes={{ content: verifyUserClasses.content }}
      size={ModalSize.SM}
      title={title}
      onCancel={(): void => setModalOpened(false)}
      footer={
        <Box textAlign="center">
          <Button
            type="submit"
            disabled={isBtnSubmitDisabled}
            fullWidth={isMobileView}
            onClick={(): void => handleSubmit()}
          >
            {i18n.t('components.forms.verificationForms.submit')}
          </Button>
        </Box>
      }
    >
      <form className={verifyUserClasses.form} onSubmit={handleSubmit}>
        {isCodeSent ? resendText : sendText}
        {userError && (
          <Typography className={clsx(verifyUserClasses.formText, verifyUserClasses.responseError)}>
            {userError.message}
          </Typography>
        )}
        <Box className={verifyUserClasses.codeField}>
          <TextField
            variant="standard"
            name="verificationCode"
            onChange={handleChange}
            onBlur={handleBlur}
            label={i18n.t('components.forms.verificationForms.label.verificationCode')}
            value={values.verificationCode}
            error={!!getError('verificationCode')}
            helperText={getError('verificationCode')}
          />
          <Typography onClick={resendCode} className={verifyUserClasses.resendCode}>
            <Trans i18n={i18n} i18nKey="components.forms.verificationForms.text.resend">
              Didn&apos;t receive the code?
              <Typography className={verifyUserClasses.resendCodeButton} component="span">
                Resubmit
              </Typography>
            </Trans>
          </Typography>
        </Box>
      </form>
    </Modal>
  );
};

import { useDispatch } from 'react-redux';

import { attachmentsApi, UpdatePatientFileReportPrescriptionVariables } from '../api';

import { IAttachmentFile } from 'graphql/types/files';
import { updatePatientFileInList } from 'store/patient/actionCreators';

interface IUpdatePatientFileReportPrescriptionReturn {
  updatePatientFileReportPrescription: (params: {
    variables: UpdatePatientFileReportPrescriptionVariables;
    options?: { onError?: VoidFunction; onSuccess?: VoidFunction };
  }) => Promise<void>;
}

type UpdatePatientFileReportPrescription = () => IUpdatePatientFileReportPrescriptionReturn;

export const useUpdatePatientFileReportPrescription: UpdatePatientFileReportPrescription = () => {
  const dispatch = useDispatch();
  const [updatePatientFileReportPrescriptionMutation] = attachmentsApi.useUpdatePatientFileReportPrescription();

  const updatePatientFileReportPrescription: IUpdatePatientFileReportPrescriptionReturn['updatePatientFileReportPrescription'] =
    async ({ variables, options }) => {
      const { data, errors } = await updatePatientFileReportPrescriptionMutation({ variables });

      if (errors) options?.onSuccess?.();

      if (data?.updatePatientFileReportPrescription) {
        dispatch(updatePatientFileInList(data.updatePatientFileReportPrescription as IAttachmentFile));
        options?.onSuccess?.();
      }
    };

  return { updatePatientFileReportPrescription };
};

import 'i18n';

import { ApolloProvider } from '@apollo/client';
import {
  ActiveProfileProvider,
  CurrentUserProvider,
  NotificationsProvider,
  PersistedDataKeys,
  PortalProvider,
  setupSentry,
  UNAUTHORIZED_ROUTES_LIST,
} from '@lib/core';
import { Portals } from '@lib/features-bll';
import { ReactIconEnvironmentProvider } from '@lib/react-components';
import { StyledEngineProvider } from '@mui/material';
import { Suspense } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import config from '../config.json';

import { UploadNewFileProvider } from './features/Files';
import { MainNotFound, RootInvalidPortalSlug, RootStyles, RootWithoutPortalRouteRedirect } from './features/Root/ui';
import { MedicalDBProvider } from './features/Root/ui/MedicalDBProvider';
import { rootStore } from './rootStore';
import { anonymousRoutes, authenticationRequiredRoutes, publicRoutes } from './routes/config';

import { PortalConfigProvider, PortalThemeProvider } from 'features/Portal';
import {
  DEPRECATED_ROUTES,
  RootDeprecatedRouteRedirect,
  RootEmptyPortalRouteRedirect,
  MainForLogged,
  MainForAnonymous,
  RootInitWebApp,
  MainForPublic,
  RootAuthorizationProvider,
} from 'features/Root/ui';
import { client } from 'graphql/apollo';
import { APP_WRAPPER_ID } from 'lib/constants';
import { NotificationsConsumer } from 'lib/consumers';
import { initGlobalSearchString } from 'lib/helpers/initGlobalSearchString';
import { initUploadDocuments } from 'lib/helpers/initUploadDocuments';
import { persistedData } from 'lib/helpers/persistedData';
import { isPlatformNative } from 'lib/helpers/platform';
import * as customValidation from 'lib/helpers/validation/customValidation';
import { LEGACY_UNAUTHORIZED_ROUTES, PORTAL_SLUG_ROUTE } from 'routes/constants';

initGlobalSearchString();

initUploadDocuments();

const isMobileAppWebView = isPlatformNative() && (isMobile || isTablet);

setupSentry({
  application: isMobileAppWebView ? 'webview' : 'web',
  dsn: config.SentryDsn,
  environment: config.SentryEnvironment,
  release: config.SentryRelease,
});

customValidation.init();

const defaultPortal = persistedData.get(PersistedDataKeys.WelmedSlug) || Portals.WELCOMEDICINE;

const disabledPortals = config.DisabledPortals.split('|');
const allPortals = Object.values(Portals);
const activePortals = allPortals.filter(portal => !disabledPortals.includes(portal));

const rootDomNode = document.getElementById(APP_WRAPPER_ID);
if (!rootDomNode) throw new Error('Failed to find the root element');
const root = createRoot(rootDomNode);

root.render(
  <ApolloProvider client={client}>
    <Provider store={rootStore}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ReactIconEnvironmentProvider>
            <PortalProvider>
              <PortalThemeProvider>
                <RootStyles />
                <RootInitWebApp />
                <Switch>
                  <Redirect exact from="/" to={`/${defaultPortal}`} />
                  <Route path={[...LEGACY_UNAUTHORIZED_ROUTES, ...UNAUTHORIZED_ROUTES_LIST]} exact>
                    <RootWithoutPortalRouteRedirect defaultPortal={defaultPortal} />
                  </Route>
                  <Route path={`${PORTAL_SLUG_ROUTE}(${activePortals.join('|')})`}>
                    <PortalConfigProvider>
                      <Route path={[...DEPRECATED_ROUTES.map(({ from }) => from)]} exact>
                        <RootDeprecatedRouteRedirect />
                      </Route>
                      <Route path={PORTAL_SLUG_ROUTE} exact>
                        <RootEmptyPortalRouteRedirect />
                      </Route>
                      <NotificationsProvider>
                        <NotificationsConsumer />
                        <CurrentUserProvider>
                          <Suspense fallback={null}>
                            <Switch>
                              <Route path={anonymousRoutes.map(route => `${PORTAL_SLUG_ROUTE}${route.path}`)}>
                                <MainForAnonymous />
                              </Route>
                              <ActiveProfileProvider>
                                <RootAuthorizationProvider>
                                  <MedicalDBProvider>
                                    <UploadNewFileProvider>
                                      <Switch>
                                        <Route path={publicRoutes.map(route => `${PORTAL_SLUG_ROUTE}${route.path}`)}>
                                          <MainForPublic />
                                        </Route>
                                        <Route
                                          path={authenticationRequiredRoutes.map(
                                            route => `${PORTAL_SLUG_ROUTE}${route.path}`
                                          )}
                                        >
                                          <MainForLogged />
                                        </Route>
                                        <Route>
                                          <MainNotFound />
                                        </Route>
                                      </Switch>
                                    </UploadNewFileProvider>
                                  </MedicalDBProvider>
                                </RootAuthorizationProvider>
                              </ActiveProfileProvider>
                            </Switch>
                          </Suspense>
                        </CurrentUserProvider>
                      </NotificationsProvider>
                    </PortalConfigProvider>
                  </Route>
                  <Route>
                    <RootInvalidPortalSlug />
                  </Route>
                </Switch>
              </PortalThemeProvider>
            </PortalProvider>
          </ReactIconEnvironmentProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

import { useContext } from 'react';

import { PrefilledPDF, AttachmentFileModalContext } from '../context';

import { Maybe } from '__generated__/types';

export const usePrefilledValueControls = (): [Maybe<PrefilledPDF>, (prefilledValue: Maybe<PrefilledPDF>) => void] => {
  const { prefilledValue, setPrefilledValue } = useContext(AttachmentFileModalContext);

  return [prefilledValue, setPrefilledValue];
};

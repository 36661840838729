import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UpdatePatientPersonalInfoVariables = Types.Exact<{
  patientId: Types.Scalars['String']['input'];
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePatientPersonalInfo = { __typename?: 'Mutation', updatePatientPersonalInfo: { __typename?: 'Patient', phoneNumber?: string | null, email?: string | null } };


export const UpdatePatientPersonalInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePatientPersonalInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"defaultValue":{"kind":"NullValue"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"defaultValue":{"kind":"NullValue"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePatientPersonalInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"phoneNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode;
export type UpdatePatientPersonalInfoMutationFn = Apollo.MutationFunction<UpdatePatientPersonalInfo, UpdatePatientPersonalInfoVariables>;

/**
 * __useUpdatePatientPersonalInfo__
 *
 * To run a mutation, you first call `useUpdatePatientPersonalInfo` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientPersonalInfo` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientPersonalInfo, { data, loading, error }] = useUpdatePatientPersonalInfo({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdatePatientPersonalInfo(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatientPersonalInfo, UpdatePatientPersonalInfoVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePatientPersonalInfo, UpdatePatientPersonalInfoVariables>(UpdatePatientPersonalInfoDocument, options);
      }
export type UpdatePatientPersonalInfoHookResult = ReturnType<typeof useUpdatePatientPersonalInfo>;
export type UpdatePatientPersonalInfoMutationResult = Apollo.MutationResult<UpdatePatientPersonalInfo>;
export type UpdatePatientPersonalInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePatientPersonalInfo, UpdatePatientPersonalInfoVariables>;
import { useHasDoctorAffiliationsToPrompt } from '../graphql/__generated__/HasDoctorAffiliationsToPrompt.query';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

type UseDoctorAffiliationsToPromptReturn = {
  affiliationsCount: number;
  loading: boolean;
};

export const useDoctorAffiliationsToPromptCount = (): UseDoctorAffiliationsToPromptReturn => {
  const isMobileAppWebView = useIsMobileAppWebView();
  const { isIncompleteDoctorProfile, isDoctor } = useActiveProfileContext();

  const { data, loading } = useHasDoctorAffiliationsToPrompt({
    fetchPolicy: 'cache-first',
    skip: !isDoctor || isIncompleteDoctorProfile || isMobileAppWebView,
  });

  return { affiliationsCount: data?.doctorAffiliationsToPrompt.length ?? 0, loading };
};

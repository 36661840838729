import { Box, Stack } from '@mui/material';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { UploadFileItem } from './UploadFileItem';

import { getAttachmentsToUpload } from 'store/attachments/selectors';

interface IUploadFileListProps {
  patientFullName: string;
  preselectedTag?: string;
}

export const UploadFileList = forwardRef<HTMLDivElement, IUploadFileListProps>(
  ({ patientFullName, preselectedTag }, ref): JSX.Element => {
    const attachmentsToUpload = useSelector(getAttachmentsToUpload);

    return (
      <Stack sx={{ gap: '16px' }}>
        {attachmentsToUpload?.map((attachment, index) => (
          <UploadFileItem
            key={attachment.fileId}
            index={index}
            attachment={attachment}
            userPreselectedTag={attachment?.fileTag || preselectedTag}
            dateOfExecution={attachment.dateOfExecution}
            currentPatientFullName={patientFullName}
            totalAttachmentFiles={attachmentsToUpload?.length}
          />
        ))}
        <Box ref={ref} />
      </Stack>
    );
  }
);

import { ChangeEventHandler, MutableRefObject } from 'react';

import { Maybe } from '__generated__/types';

export const GetFileInputHandler =
  (uploadNewFilesRef: MutableRefObject<Maybe<HTMLInputElement>>) =>
  (handleFileChange: ChangeEventHandler<HTMLInputElement>): JSX.Element => (
    <input
      ref={uploadNewFilesRef}
      value=""
      type="file"
      onChange={handleFileChange}
      multiple
      style={{ display: 'none' }}
    />
  );

import { createAction } from 'typesafe-actions';

import { ActionTypeList } from './types';

import { IAttachmentFile } from 'graphql/types/files';
import {
  IAbdomenUltrasoundData,
  IAllegryToHymenopteraData,
  IAnaphylacticShockData,
  IBoneDensitometryData,
  IBoneImagingData,
  ICkitMutationData,
  IEditPatientFields,
  IImmunophenotypingData,
  IMastocytosisData,
  IMastocytosisHistoryData,
  IMedicalIndexData,
  IMedicalIndexScoreData,
  IOtherAllergiesData,
  IPatientMedicalData,
  ISignAndSymptomsCommonData,
  ITumoursData,
} from 'graphql/types/patient';
import { ICurrentResidenceAddress, IUser } from 'graphql/types/user';

export const setEhrDataLoading = createAction(
  ActionTypeList.SET_PATIENT_EHR_DATA_LOADING,
  (isPatientDataIsLoading: boolean) => ({ isPatientDataIsLoading })
)();

export const setPatientMedicalData = createAction(
  ActionTypeList.SET_PATIENT_MEDICAL_DATA,
  (patientMedical: IPatientMedicalData) => ({ patientMedical })
)();

export const setPatientMedicalDataUser = createAction(
  ActionTypeList.SET_PATIENT_MEDICAL_DATA_USER,
  (user: Partial<IUser>) => ({ user })
)();

export const removePatientMedicalData = createAction(ActionTypeList.REMOVE_PATIENT_MEDICAL_DATA)();

export const updatePatientFileList = createAction(
  ActionTypeList.UPDATE_PATIENT_FILE_LIST,
  (newPatientFile: IAttachmentFile) => ({ newPatientFile })
)();

export const updatePatientFileInList = createAction(
  ActionTypeList.UPDATE_PATIENT_FILE_IN_LIST,
  (newPatientFile: IAttachmentFile) => ({ newPatientFile })
)();

export const editPatientFileInList = createAction(
  ActionTypeList.EDIT_PATIENT_FILE_IN_LIST,
  (editedPatientFile: IAttachmentFile) => ({ editedPatientFile })
)();

export const cancelPatientFileInList = createAction(
  ActionTypeList.CANCEL_PATIENT_FILE_IN_LIST,
  (canceledPatientFile: IAttachmentFile) => ({ canceledPatientFile })
)();

export const updatePatientInfo = createAction(
  ActionTypeList.UPDATE_PATIENT_INFO,
  (newPatientFields: IEditPatientFields) => ({
    newPatientFields,
  })
)();

export const setCancelledFilesListPatient = createAction(
  ActionTypeList.SET_CANCELLED_FILES_LIST_PATIENT,
  (patientUser: IUser) => ({ patientUser })
)();

export const restorePatientCancelledFile = createAction(
  ActionTypeList.RESTORE_PATIENT_CANCELLED_FILE,
  (restoredFile: IAttachmentFile) => ({ restoredFile })
)();

export const setPatientMastocytosisData = createAction(
  ActionTypeList.SET_PATIENT_MASTOCYTOSIS_DATA,
  (patientMastocytosisData: IMastocytosisData) => ({ patientMastocytosisData })
)();

export const setPatientMastocytosisHistoryData = createAction(
  ActionTypeList.SET_PATIENT_MASTOCYTOSIS_HISTORY_DATA,
  (patientMastocytosisHistoryData: IMastocytosisHistoryData) => ({ patientMastocytosisHistoryData })
)();

export const removePatientMastocytosisHistoryData = createAction(
  ActionTypeList.REMOVE_PATIENT_MASTOCYTOSIS_HISTORY_DATA
)();

export const setTumoursMedicalDataParameter = createAction(
  ActionTypeList.SET_TUMOURS_MEDICAL_DATA_PARAMETER,
  (tumoursMedicalDataParameter: ITumoursData) => ({ tumoursMedicalDataParameter })
)();

export const setAnaphylacticShockMedicalDataParameter = createAction(
  ActionTypeList.SET_ANAPHYLACTIC_SHOCK_MEDICAL_DATA_PARAMETER,
  (anaphylacticShockMedicalDataParameter: IAnaphylacticShockData) => ({ anaphylacticShockMedicalDataParameter })
)();

export const setAllergyToHymenopteraMedicalDataParameter = createAction(
  ActionTypeList.SET_ALLERGY_TO_HYMENOPTERA_MEDICAL_DATA_PARAMETER,
  (allergyToHymenopteraMedicalDataParameter: IAllegryToHymenopteraData) => ({
    allergyToHymenopteraMedicalDataParameter,
  })
)();

export const setOtherAllergiesMedicalDataParameter = createAction(
  ActionTypeList.SET_OTHER_ALLERGIES_MEDICAL_DATA_PARAMETER,
  (otherAllergiesMedicalDataParameter: IOtherAllergiesData) => ({ otherAllergiesMedicalDataParameter })
)();

export const setSyncopesMedicalDataParameter = createAction(
  ActionTypeList.SET_SYNCOPES_MEDICAL_DATA_PARAMETER,
  (syncopesMedicalDataParameter: ISignAndSymptomsCommonData) => ({ syncopesMedicalDataParameter })
)();

export const setGastrointestinalDisordersMedicalDataParameter = createAction(
  ActionTypeList.SET_GASTROINTESTINAL_DISORDERS_MEDICAL_DATA_PARAMETER,
  (gastrointestinalDisordersMedicalDataParameter: ISignAndSymptomsCommonData) => ({
    gastrointestinalDisordersMedicalDataParameter,
  })
)();

export const setSkinInvolvementMedicalDataParameter = createAction(
  ActionTypeList.SET_SKIN_INVOLVEMENT_MEDICAL_DATA_PARAMETER,
  (skinInvolvementMedicalDataParameter: ISignAndSymptomsCommonData) => ({ skinInvolvementMedicalDataParameter })
)();

export const setBoneDensitometryMedicalDataParameter = createAction(
  ActionTypeList.SET_BONE_DENSITOMETRY_MEDICAL_DATA_PARAMETER,
  (boneDensitometryMedicalDataParameter: IBoneDensitometryData) => ({ boneDensitometryMedicalDataParameter })
)();

export const setBoneImagingMedicalDataParameter = createAction(
  ActionTypeList.SET_BONE_IMAGING_MEDICAL_DATA_PARAMETER,
  (boneImagingMedicalDataParameter: IBoneImagingData) => ({ boneImagingMedicalDataParameter })
)();

export const setAbdomenUltrasoundMedicalDataParameter = createAction(
  ActionTypeList.SET_ABDOMEN_ULTRASOUND_MEDICAL_DATA_PARAMETER,
  (abdomenUltrasoundMedicalDataParameter: IAbdomenUltrasoundData) => ({ abdomenUltrasoundMedicalDataParameter })
)();

export const setImmunophenotypingMedicalDataParameter = createAction(
  ActionTypeList.SET_IMMUNOPHENOTYPING_MEDICAL_DATA_PARAMETER,
  (immunophenotypingMedicalDataParameter: IImmunophenotypingData) => ({ immunophenotypingMedicalDataParameter })
)();

export const setCkitMutationMedicalDataParameter = createAction(
  ActionTypeList.SET_C_KIT_MUTATION_MEDICAL_DATA_PARAMETER,
  (cKitMutationMedicalDataParameter: ICkitMutationData) => ({ cKitMutationMedicalDataParameter })
)();

export const setChangePatientMedicalDataResidenceAddress = createAction(
  ActionTypeList.SET_CHANGE_PATIENT_MEDICAL_DATA_RESIDENCE_ADDRESS,
  (currentResidenceAddress: ICurrentResidenceAddress) => ({ currentResidenceAddress })
)();

export const setPatientSokalIndexScore = createAction(
  ActionTypeList.SET_SOKAL_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setSokalIndexData = createAction(ActionTypeList.ADD_SOKAL_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setPatientELTSIndexScore = createAction(
  ActionTypeList.SET_ELTS_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientMpn10IndexScore = createAction(
  ActionTypeList.SET_MPN10_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientcTnmIndexScore = createAction(
  ActionTypeList.SET_CTNM_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientpTnmIndexScore = createAction(
  ActionTypeList.SET_PTNM_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientTnmIndexScore = createAction(
  ActionTypeList.SET_TNM_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientDipssPlusIndexScore = createAction(
  ActionTypeList.SET_DIPSS_PLUS_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setIpssrMedicalIndexScore = createAction(
  ActionTypeList.SET_IPSSR_MEDICAL_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setMipss70MedicalIndexScore = createAction(
  ActionTypeList.SET_MIPSS70_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setPatientMysecPmIndexScore = createAction(
  ActionTypeList.SET_MYSEC_PM_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setELTSIndexData = createAction(ActionTypeList.ADD_ELTS_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setPatientCharlsonIndexScore = createAction(
  ActionTypeList.SET_CHARLSON_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setCharlsonIndexData = createAction(
  ActionTypeList.ADD_CHARLSON_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({ indexData })
)();

export const setMpn10IndexData = createAction(ActionTypeList.ADD_MPN10_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setcTnmIndexData = createAction(ActionTypeList.ADD_CTNM_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setpTnmIndexData = createAction(ActionTypeList.ADD_PTNM_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setTnmIndexData = createAction(ActionTypeList.ADD_TNM_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setTnmAsco2021StomachIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_STOMACH_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setTnmAsco2021PancreasIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_PANCREAS_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setTnmAsco2021DuodenumAndVaterAmpullaIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setTnmAsco2021JejunumAndIleumIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_JEJUNUM_AND_ILEUM_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setTnmAsco2021AppendixIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_APPENDIX_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setTnmAsco2021ColonAndRectumIndexData = createAction(
  ActionTypeList.ADD_TNM_ASCO_2021_COLON_AND_RECTUM_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({
    indexData,
  })
)();

export const setDipssPlusIndexData = createAction(
  ActionTypeList.ADD_DIPSS_PLUS_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({ indexData })
)();

export const setIpssrIndexData = createAction(
  ActionTypeList.ADD_IPSSR_MEDICAL_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({ indexData })
)();

export const setMipss70IndexData = createAction(
  ActionTypeList.ADD_MIPSS70_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({ indexData })
)();

export const setMysecPmIndexData = createAction(
  ActionTypeList.ADD_MYSEC_PM_INDEX_DATA,
  (indexData: IMedicalIndexData) => ({ indexData })
)();

export const setPatientCalculations = createAction(
  ActionTypeList.SET_PATIENT_CALCULATIONS,
  (patientCalculations: IMedicalIndexData[]) => ({ patientCalculations })
)();

export const addPatientCalculations = createAction(
  ActionTypeList.ADD_PATIENT_CALCULATIONS,
  (patientCalculations: IMedicalIndexData) => ({ patientCalculations })
)();

export const setPatientDipssIndexScore = createAction(
  ActionTypeList.SET_DIPSS_INDEX_SCORE,
  (indexScore: IMedicalIndexScoreData[]) => ({ indexScore })
)();

export const setDipssIndexData = createAction(ActionTypeList.ADD_DIPSS_INDEX_DATA, (indexData: IMedicalIndexData) => ({
  indexData,
}))();

export const setNoEhrPrivacies = createAction(ActionTypeList.SET_NO_EHR_PRIVACIES, (noEhrPrivacies: boolean) => ({
  noEhrPrivacies,
}))();

import { useModalState } from '@lib/react-components';

interface IUseUploadFilesModalControlsReturn {
  isConfirmCloseModalOpen?: boolean;
  isUploadModalOpen?: boolean;
  closeUploadModal: VoidFunction;
  openConfirmCloseModal: VoidFunction;
  openUploadModal: VoidFunction;
  closeConfirmCloseModal: VoidFunction;
}

type UseUploadFilesModalControls = () => IUseUploadFilesModalControlsReturn;

export const useUploadFilesModalControls: UseUploadFilesModalControls = () => {
  const [isConfirmCloseModalOpen, openConfirmCloseModal, closeConfirmCloseModal] = useModalState();
  const [isUploadModalOpen, openUploadModal, closeUploadModal] = useModalState();

  return {
    isUploadModalOpen,
    isConfirmCloseModalOpen,
    closeUploadModal,
    openConfirmCloseModal,
    openUploadModal,
    closeConfirmCloseModal,
  };
};

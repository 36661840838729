import { PersistedDataKeys, ROUTE_PATHS, CurrentUserContext } from '@lib/core';
import { useAuthenticatedUserLazyQuery } from '@lib/features-bll';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { useUserModelLoadActiveProfiles } from 'features/Users/model/useUserModelLoadActiveProfiles';
import { persistedData } from 'lib/helpers/persistedData';
import { routeRequiresAuthentication } from 'routes/model';

export enum LoadUserDataErrorCode {
  NoAuthenticatedUser = 'NoAuthenticatedUser',
  ProfileIsBlocked = 'ProfileIsBlocked',
  NoProfile = 'NoProfile',
  IncompleteProfile = 'IncompleteProfile',
}

interface ILoginModelAuthenticateProps {
  withRedirect?: boolean;
  onError?: (errorCode: LoadUserDataErrorCode) => void;
  onSuccess?: VoidFunction;
}

interface IUseLoginModelAuthenticateReturn {
  loading: boolean;
  loadUserData: (props?: ILoginModelAuthenticateProps) => Promise<void>;
}

export const useLoginModelLoadUserData = (): IUseLoginModelAuthenticateReturn => {
  const { goToPath } = useRootModelPath();
  const { search, pathname } = useLocation();
  const [getAuthenticatedUser, { loading }] = useAuthenticatedUserLazyQuery();
  const { loadActiveProfiles } = useUserModelLoadActiveProfiles();
  const { setCurrentUser } = useContext(CurrentUserContext);

  const loadUserData: IUseLoginModelAuthenticateReturn['loadUserData'] = async ({
    onError,
    onSuccess,
    withRedirect = true,
  } = {}) => {
    const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);

    if (!accessToken) {
      const [, , ...routeParts] = pathname.split('/');
      const currentRoute = `/${routeParts.join('/')}`;

      if (routeRequiresAuthentication(currentRoute)) {
        onError?.(LoadUserDataErrorCode.NoAuthenticatedUser);
      }

      return;
    }

    const { data: { authenticatedUser } = {} } = await getAuthenticatedUser();

    if (authenticatedUser) {
      setCurrentUser(authenticatedUser);
    } else {
      // TODO: if user has token, doesn't have profile on this portal, is on public page, this returns anyway
      onError?.(LoadUserDataErrorCode.NoAuthenticatedUser);

      return;
    }

    const errorCode = loadActiveProfiles({ profileList: authenticatedUser.profileList });

    if (errorCode) {
      onError?.(errorCode);

      return;
    }

    if (withRedirect) {
      goToPath({ path: ROUTE_PATHS.dashboard, search });
    }

    onSuccess?.();
  };

  return { loading, loadUserData };
};

import { attachmentsApi } from '../api';
import { CancelFileHookResult } from '../api/graphql/__generated__/CancelFile.mutation';

import { i18n } from 'i18n';

interface IUseCancelFileParams {
  isPatient?: boolean;
}

export const useCancelFile = ({ isPatient }: IUseCancelFileParams): CancelFileHookResult[0] => {
  const [cancelFileMutation] = attachmentsApi.useCancelFile({
    notifications: {
      onCompleted: (isPatient
        ? i18n.t('components.fileActions.cancel.successNotification')
        : i18n.t('components.fileActions.remove.successNotification')) as string,
    },
  });

  return cancelFileMutation;
};

import { convertStringToNumber, useExecuteOnTimeout } from '@lib/utils';

import config from '../../../../../../../../config.json';

import { AiLoadingModal } from 'features/Attachments/components/UploadFiles/AiProcessingModals/AiLoadingModal';

interface IAiParamsExtractionLoadingModalProps {
  onAiParamsExtractionTimeout: VoidFunction;
}

export const AiParamsExtractionLoadingModal = ({
  onAiParamsExtractionTimeout,
}: IAiParamsExtractionLoadingModalProps): JSX.Element => {
  useExecuteOnTimeout(onAiParamsExtractionTimeout, convertStringToNumber(config.AiParamsExtractionTimeout));

  return <AiLoadingModal />;
};

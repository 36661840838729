import { ROUTE_PATHS } from '@lib/core';
import { WebToNativeActions } from '@lib/features-bll';
import {
  CircularPercentProgress,
  FlexBox,
  Modal,
  openLinkInNewTab,
  openLinkSelfTab,
  StatusCard,
  useBreakpoints,
} from '@lib/react-components';
import { getFormatDate } from '@lib/utils';
import { Button, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { useStyle } from './styles';
import { IExtractParametersModalProps } from './types';

import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { createNativeAction, usePlatformBasedAction } from 'lib/hooks/usePlatformBasedAction.hook';

export const ExtractParametersModal = ({ onCancel, file, patientId }: IExtractParametersModalProps): JSX.Element => {
  const { classes: localClasses } = useStyle();
  const { getPath } = useRootModelPath();
  const { isMobileView } = useBreakpoints();
  const isMobileAppWebView = useIsMobileAppWebView();
  const platformBasedAction = usePlatformBasedAction();

  const onConfirm: VoidFunction = () => {
    const params = {
      patientId: patientId || '',
      fileId: file.id || '',
    };
    const patientAindoFileParametersPath = getPath({
      path: ROUTE_PATHS.patientAindoFileParameters,
      pathParams: params,
    });

    platformBasedAction({
      webAction: () =>
        isMobileView || isMobileAppWebView
          ? openLinkSelfTab(patientAindoFileParametersPath)
          : openLinkInNewTab(patientAindoFileParametersPath),
      nativeAction: createNativeAction(WebToNativeActions.openAindoFileParameters, params),
    });
    onCancel();
  };

  return (
    <Modal
      onCancel={onCancel}
      classes={{
        title: localClasses.modalTitle,
      }}
      title={i18n.t('features.attachments.extractParametersModal.title')}
      footer={
        <Stack direction={isMobileView ? 'column' : 'row'} spacing="14px">
          <Button onClick={onConfirm}>{i18n.t('general.buttons.continue')}</Button>
          <Button variant="secondary" onClick={onCancel}>
            {i18n.t('general.buttons.cancel')}
          </Button>
        </Stack>
      }
    >
      <Typography mb="24px" variant="body1">
        {i18n.t('features.attachments.extractParametersModal.description')}
      </Typography>
      {!!file.pdfParametersRevision?.completionPercent && (
        <StatusCard
          mb="12px"
          status="infoAlert"
          withoutIcon={!isMobileView}
          text={
            <FlexBox justifyContent="space-between" alignItems="center">
              <Stack gap={isMobileView ? '4px' : '2px'}>
                <Typography variant="h7">{i18n.t('features.attachments.extractParametersModal.fileTitle')}</Typography>
                <Typography variant="body2">
                  <Trans
                    i18n={i18n}
                    i18nKey="features.attachments.extractParametersModal.filePercentage"
                    values={{ percentage: Math.round(file.pdfParametersRevision.completionPercent ?? 0) }}
                    components={{ 1: <b /> }}
                  />
                </Typography>
                <Typography variant="body2">
                  <Trans
                    i18n={i18n}
                    i18nKey="features.attachments.extractParametersModal.fileUpdatedAt"
                    values={{ date: getFormatDate(file.pdfParametersRevision.lastUpdatedAt || '') }}
                    components={{ 1: <b /> }}
                  />
                </Typography>
              </Stack>
              {!isMobileView && <CircularPercentProgress percent={file.pdfParametersRevision?.completionPercent} />}
            </FlexBox>
          }
        />
      )}
    </Modal>
  );
};

/* eslint @typescript-eslint/explicit-function-return-type: 0 */
import { action } from 'typesafe-actions';

import { ActionTypeList, IAttachmentToUpload } from './types';

export const setAttachmentsToUpload = (attachmentsToUpload: IAttachmentToUpload[]) =>
  action(ActionTypeList.SET_ATTACHMENTS_TO_UPLOAD, { attachmentsToUpload });

export const removeAttachmentsToUpload = () => action(ActionTypeList.REMOVE_ATTACHMENTS_TO_UPLOAD);

export const addAttachmentToList = (attachmentToUpload: IAttachmentToUpload) =>
  action(ActionTypeList.ADD_ATTACHMENT_TO_LIST, { attachmentToUpload });

export const addAttachmentsToList = (attachmentsToUpload: IAttachmentToUpload[]) =>
  action(ActionTypeList.ADD_ATTACHMENTS_TO_LIST, { attachmentsToUpload });

export const removeAttachmentFromList = (index: number) =>
  action(ActionTypeList.REMOVE_ATTACHMENT_FROM_LIST, { index });

export const updateAttachmentInList = (payload: {
  attachmentToUpdateIndex: number;
  newParameters: Partial<IAttachmentToUpload>;
}) => action(ActionTypeList.UPDATE_ATTACHMENT_IN_LIST, payload);

export const updateAttachmentByFileId = (payload: { fileId: string; newParameters: Partial<IAttachmentToUpload> }) =>
  action(ActionTypeList.UPDATE_ATTACHMENT_BY_FILE_ID, payload);

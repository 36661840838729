import { NotificationType, useNotifications } from '@lib/core';

import { attachmentsApi } from '../api';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useUploadFilesStore } from 'features/Attachments/components/UploadFiles/hooks';
import { i18n } from 'i18n';

type UseAiParamsExtractionSubscription = (onAiParamsError: VoidFunction) => void;

export const useAiParamsExtractionSubscription: UseAiParamsExtractionSubscription = () => {
  const { isDoctor } = useActiveProfileContext();
  const { showNotification } = useNotifications();
  const { setAindoFile } = useUploadFilesStore();

  attachmentsApi.usePdfParametersExtracted({
    onData: ({ data: { data } }) => {
      if (data?.pdfParametersExtracted) {
        setAindoFile(data.pdfParametersExtracted);
      }
    },
    onError: (): void => {
      showNotification(i18n.t('features.medicalData.aindoFileParameters.extractionError'), NotificationType.WARNING);
    },
    skip: !isDoctor,
  });
};

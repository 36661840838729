import { useCallback, useContext } from 'react';

import { AttachmentFileModalContext, PrefilledPDF } from '../context';

import { PDFDataStorageType } from '__generated__/types';

type UseEditPrefilledValueReturn = (prefilled: PrefilledPDF) => void;

export const useEditPrefilledValue = (): UseEditPrefilledValueReturn => {
  const {
    setPrefilledValue,
    setPrescriptionModalOpened,
    setReportModalOpened,
    setDicomReportModalOpened,
    setMultidisciplinaryReportModalOpened,
  } = useContext(AttachmentFileModalContext);

  return useCallback(
    (prefilled: PrefilledPDF) => {
      if (prefilled) {
        setPrefilledValue({ ...prefilled, threadId: 'thread' });

        switch (prefilled.type) {
          case PDFDataStorageType.PRESCRIPTION:
            setPrescriptionModalOpened(true);
            break;
          case PDFDataStorageType.REPORT:
            setReportModalOpened(true);
            break;
          case PDFDataStorageType.DICOM_REPORT:
            setDicomReportModalOpened(true);
            break;
          case PDFDataStorageType.MULTIDISCIPLINARY_REPORT:
            setMultidisciplinaryReportModalOpened(true);
            break;

          default:
            break;
        }
      }
    },
    [setPrefilledValue, setPrescriptionModalOpened, setDicomReportModalOpened, setReportModalOpened]
  );
};

import { useModalState, Yup } from '@lib/react-components';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';

import { AttachmentFileModalContext, PrefilledPDF } from '../context';

import { usePrefilledValueControls } from './usePrefilledValueControls';
import { useSubmitPdfData } from './useSubmitPdfData';

import { Maybe, PDFDataStorageType } from '__generated__/types';
import { IMedicalReportRecipient, MedicalReportFormValues, MedicalReportModalProps } from 'features/MedicalReports';
import { IVerifyIdentityModalProps } from 'features/Users/components/VerifyIdentityModal/types';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface IUseAttachmentsModalParams<FormValues extends MedicalReportFormValues = MedicalReportFormValues> {
  attachmentType: PDFDataStorageType;
  doctor: IMedicalReportRecipient;
  handleSubjects: (values: FormValues) => string[];
  validationSchema: Yup.AnyObjectSchema;
  getInitialValues: (prefilledPdf: Maybe<PrefilledPDF>, doctor: IMedicalReportRecipient) => FormValues;
  onFinished: VoidFunction;
  onCloseModal: VoidFunction;
}

export interface IUseAttachmentsModalReturn<ModalProps extends MedicalReportModalProps = MedicalReportModalProps> {
  isOtpModalOpen: boolean;
  modalProps: Omit<ModalProps, 'patient' | 'doctor'>;
  verifyIdentityProps: IVerifyIdentityModalProps;
}

export const useAttachmentModal = <
  ModalProps extends MedicalReportModalProps = MedicalReportModalProps,
  FormValues extends MedicalReportFormValues = MedicalReportFormValues,
>({
  attachmentType,
  validationSchema,
  getInitialValues,
  doctor,
  handleSubjects,
  onFinished,
  onCloseModal,
}: IUseAttachmentsModalParams<FormValues>): IUseAttachmentsModalReturn<ModalProps> => {
  const [isOtpModalOpen, openOtpModal, closeOtpModal] = useModalState();

  const [prefilledValue] = usePrefilledValueControls();

  const [isPrescriptionInvalid, setIsPrescriptionInvalid] = useState(false);

  const { onSubmit, onResetModal } = useSubmitPdfData<FormValues>({
    handleSubjects,
    fileType: attachmentType,
    isInvalidated: isPrescriptionInvalid,
  });

  const onCreate = (): void => {
    openOtpModal();
  };

  const formikProps = useFormik<FormValues>({
    initialValues: getInitialValues(prefilledValue, doctor),
    enableReinitialize: true,
    onSubmit: onCreate,
    validateOnMount: true,
    validationSchema,
  });

  const { errors, touched, values, resetForm } = formikProps;
  const getError = getFormErrors(errors, touched);

  const { setHasFinishedDocumentCreation } = useContext(AttachmentFileModalContext);

  const onSubmitOtp = (otp: string): void => {
    onSubmit(values, { resetForm }, otp, () => {
      setHasFinishedDocumentCreation(true);
      onFinished();
    });
  };

  const handleCloseModal = (): void => {
    onResetModal();
    onCloseModal?.();
  };

  // @ts-expect-error todo Fix typings
  const modalProps: ModalProps = {
    getError,
    prefilledValue,
    onClose: handleCloseModal,
    formikProps,
    isPrescriptionInvalid,
    setIsPrescriptionInvalid,
  };

  return {
    modalProps,
    isOtpModalOpen,
    verifyIdentityProps: {
      onSubmitOtp,
      onAbortOtp: (): void => {
        closeOtpModal();
      },
      onErrorOtp: () => closeOtpModal(),
    },
  };
};

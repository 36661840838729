import { Portals } from '@lib/features-bll';
import { getFileTags } from '@lib/features-ui';
import { DatePicker, Select, SelectWithLabel, StatusCard, useBreakpoints } from '@lib/react-components';
import { Box, Stack, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { useStyles } from './styles';
import { IBaseFileDataSectionProps } from './types';

import { FileTag } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { usePortalSlug } from 'features/Portal';
import { i18n } from 'i18n';

const FAKE_PRIVATE_FILE_TAG = {
  value: 'fakeTag',
  label: i18n.t('features.attachments.baseFileDataSection.privateFile'),
};

export const BaseFileDataSection = ({
  isPatientPrivateFile,
  originalFileName,
  fileName,
  fileNameErrorText,
  fileDateOfExecutionErrorText,
  fileTagErrorText,
  executionDate,
  fileTag,
  aiFileTag,
  withTagsSelect = true,
  handleFileNameChange,
  handleExecutionDateChange,
  handleFileTagChange,
  handleFileNameBlur,
  handleFileTagBlur,
  handleDateOfExecutionBlur,
  handleIsFilePrivateChange,
  onFileRedirect,
}: IBaseFileDataSectionProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isPatient } = useActiveProfileContext();
  const { isMobileView } = useBreakpoints();

  const isAiFileTagMismatchWithUser = !!(aiFileTag && fileTag !== aiFileTag);

  const portalSlug = usePortalSlug();
  const isMchPortal = portalSlug === Portals.MARIACECILIAHOSPITAL;

  const fileTags = getFileTags({ isPatient, isMchPortal });

  const aiFileTagLabel = fileTags.find(sectionTag => sectionTag.value === aiFileTag)?.label;

  return (
    <Stack gap="8px">
      <Box className={localClasses.wrapper}>
        <TextField
          variant="standard"
          label={`${i18n.t('components.uploadFiles.fileName')}*`}
          placeholder={originalFileName}
          onChange={handleFileNameChange}
          onBlur={handleFileNameBlur}
          value={fileName}
          error={!!fileNameErrorText}
          helperText={fileNameErrorText}
        />
        {!isMobileView && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              width: 'fit-content',
            }}
          >
            <Typography onClick={onFileRedirect} component="p" variant="bodyLink">
              {i18n.t('general.buttons.preview')}
            </Typography>
          </Box>
        )}
        <DatePicker
          label={`${i18n.t('features.attachments.baseFileDataSection.executionDate')}*`}
          errorText={fileDateOfExecutionErrorText}
          value={executionDate}
          onChange={handleExecutionDateChange}
          handleInputBlur={handleDateOfExecutionBlur}
          disableFuture
          classes={{ wrapper: clsx({ [localClasses.singleLine]: !withTagsSelect }) }}
        />
        {isMobileView && (
          <Typography onClick={onFileRedirect} component="p" variant="bodyLink">
            {i18n.t('general.buttons.preview')}
          </Typography>
        )}
        {withTagsSelect && (
          <SelectWithLabel
            classes={{ icon: localClasses.icon }}
            value={isPatientPrivateFile ? FAKE_PRIVATE_FILE_TAG.value : fileTag || ''}
            items={[...fileTags, ...(isPatient ? [FAKE_PRIVATE_FILE_TAG] : [])]}
            variant="standard"
            onChange={({ target: { value } }): void => {
              if (isPatient) {
                handleIsFilePrivateChange(value === FAKE_PRIVATE_FILE_TAG.value);
              }

              handleFileTagChange(value as FileTag);
            }}
            onBlur={handleFileTagBlur}
            label={`${i18n.t('features.attachments.baseFileDataSection.fileTagSelectLabel')}*`}
            error={!!fileTagErrorText}
            helperText={fileTagErrorText}
          />
        )}
        {isPatientPrivateFile && (
          <>
            <StatusCard
              classes={{ cardWrapper: localClasses.singleLine }}
              status="warning"
              text={i18n.t('features.attachments.baseFileDataSection.privateFileWarning')}
            />
            <Select
              classes={{ icon: localClasses.icon }}
              className={localClasses.singleLine}
              value={fileTag || ''}
              items={fileTags}
              variant="standard"
              onChange={({ target: { value } }): void => handleFileTagChange(value as FileTag)}
            />
          </>
        )}
      </Box>
      {isAiFileTagMismatchWithUser && aiFileTagLabel && (
        <StatusCard
          status="warning"
          text={
            <Trans
              i18n={i18n}
              i18nKey="features.attachments.baseFileDataSection.fileTagMismatchWarning"
              components={{ strong: <strong /> }}
              values={{ aiFileTag: aiFileTagLabel }}
            />
          }
        />
      )}
    </Stack>
  );
};

import { useContext } from 'react';

import { AttachmentFileModalContext } from '../context';

export const useDicomReportModalControls = (): [
  boolean | undefined,
  (isModalOpened: boolean) => void,
  draftMessage: string | undefined,
  ((draftMessage: string) => void) | undefined,
] => {
  const { isDicomReportModalOpened, setDicomReportModalOpened, initialText, setInitialText } =
    useContext(AttachmentFileModalContext);

  return [isDicomReportModalOpened, setDicomReportModalOpened, initialText, setInitialText];
};

import { isDateLessOrEqualThanToday, isValidDate } from '@lib/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAttachmentsToUpload } from 'store/attachments/selectors';

export const useIsSaveButtonDisabled = (): boolean => {
  const attachmentsToUpload = useSelector(getAttachmentsToUpload);
  const [areTagsFilled, setTagsFilled] = useState(false);
  const [areLabelsFilled, setLabelsFilled] = useState(false);
  const [areDateOfExecutionFilled, setDateOfExecutionFilled] = useState(false);
  const [areDateOfExecutionValid, setAreDateOfExecutionValid] = useState(false);

  useEffect(() => {
    if (attachmentsToUpload) {
      setLabelsFilled(!!attachmentsToUpload.every(({ label }) => label));
      setDateOfExecutionFilled(!!attachmentsToUpload.every(({ dateOfExecution }) => dateOfExecution));
      setTagsFilled(attachmentsToUpload.every(({ fileTag }) => !!fileTag));
      setAreDateOfExecutionValid(
        attachmentsToUpload.every(
          ({ dateOfExecution }) =>
            !!(dateOfExecution && isValidDate(dateOfExecution) && isDateLessOrEqualThanToday(dateOfExecution))
        )
      );
    }
  }, [attachmentsToUpload]);

  return (
    !attachmentsToUpload?.length ||
    !areTagsFilled ||
    !areLabelsFilled ||
    !areDateOfExecutionFilled ||
    !areDateOfExecutionValid
  );
};

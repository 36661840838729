import { Loader, ModalSize, useBreakpoints } from '@lib/react-components';
import { getFilesListFromBlob } from '@lib/utils';
import { isNil } from 'lodash-es';
import { ChangeEventHandler, ReactNode, useEffect, useRef, useState } from 'react';

import { AiProcessingModals } from './AiProcessingModals';
import { ConfirmCloseModal } from './ConfirmCloseModal';
import { FileUploadModalFooter } from './FileUploadModalFooter';
import {
  useAfterUploadFilesAction,
  useConfirmAttachments,
  useResetFileList,
  useUploadAttachments,
  useUploadFilesModalControls,
  useUploadFilesStore,
} from './hooks';
import { useStyles } from './styles';
import { UploadFileList } from './UploadFileList';
import { UploadFileModal } from './UploadFileModal';

import { Maybe } from '__generated__/types';
import { useDocumentsStore } from 'features/Attachments/model';
import { usePatientData } from 'features/Patient/model';
import { i18n } from 'i18n';

export interface IUploadFilesProps {
  patientId?: Maybe<string>;
  threadId?: string;
  preselectedTag?: string;
  attachToIntramed?: boolean;
  attachToRequest?: boolean;
  attachToChat?: boolean;
  preSaveAction?: () => Promise<void>;
  loadingAction?: (state: boolean) => void;
  fileInput: (fileHandler: ChangeEventHandler<HTMLInputElement>) => ReactNode;
  isThreadAindoFlow?: boolean;
}

export const UploadFiles = ({
  fileInput,
  patientId,
  threadId,
  preSaveAction,
  loadingAction,
  attachToIntramed,
  attachToRequest,
  attachToChat,
  preselectedTag,
  isThreadAindoFlow,
}: IUploadFilesProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isMobileView } = useBreakpoints();
  const { uploadedDocuments, setUploadedDocuments } = useDocumentsStore();
  const anchorBoxRef = useRef<Maybe<HTMLDivElement>>(null);
  const [hasAnchorRef, setHasAnchorRef] = useState(false);
  const { isAiProcessingModalsVisible, isAiParamsDataExtractionModalsVisible } = useUploadFilesStore();
  const {
    isConfirmCloseModalOpen,
    isUploadModalOpen,
    closeUploadModal,
    openConfirmCloseModal,
    openUploadModal,
    closeConfirmCloseModal,
  } = useUploadFilesModalControls();
  const { patientPortalUserId, patientFullName, loading } = usePatientData(patientId);
  const { confirmAttachments, isConfirmAttachmentsLoading } = useConfirmAttachments({
    patientId,
    threadId,
    preSaveAction,
    loadingAction,
    attachToIntramed,
    attachToRequest,
    attachToChat,
  });
  const {
    uploadAttachments,
    isUploadAttachmentsLoading,
    reset: additionalReset,
    setIsLoading,
  } = useUploadAttachments({
    patientPortalUserId,
    preselectedTag,
    onComplete: openUploadModal,
  });
  const { resetFileList } = useResetFileList(additionalReset);
  const afterUploadFilesAction = useAfterUploadFilesAction();

  const onFilesUpload = async (): Promise<void> => {
    await confirmAttachments();
    resetFileList();
    setUploadedDocuments([]);
    closeUploadModal();

    if (!isThreadAindoFlow) {
      afterUploadFilesAction();
    }
  };

  useEffect(() => {
    if (!isNil(anchorBoxRef.current) && !isUploadAttachmentsLoading) {
      anchorBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isUploadAttachmentsLoading, anchorBoxRef.current]);

  useEffect(() => {
    if (uploadedDocuments.length) {
      openUploadModal();
      setIsLoading(true);
      const fileList = getFilesListFromBlob(uploadedDocuments);
      uploadAttachments(fileList, uploadedDocuments);
    }
  }, [uploadedDocuments]);

  return (
    <>
      {fileInput(({ target: { files } }): Promise<void> => uploadAttachments(files))}
      {isUploadAttachmentsLoading !== undefined && (
        <>
          {isAiProcessingModalsVisible && (
            <AiProcessingModals
              isAiParamsDataExtractionModalsVisible={isAiParamsDataExtractionModalsVisible}
              onAiParamsFinish={resetFileList}
              patientId={patientId}
            />
          )}
          {isConfirmCloseModalOpen && (
            <ConfirmCloseModal
              onCancel={(): void => {
                closeConfirmCloseModal();
                openUploadModal();
              }}
              onConfirm={(): void => {
                resetFileList();
                setUploadedDocuments([]);
                closeConfirmCloseModal();
                setHasAnchorRef(false);
              }}
            />
          )}
          {isUploadModalOpen && (
            <UploadFileModal
              classes={{
                contentBox: localClasses.contentBox,
                content: localClasses.content,
                paper: localClasses.paper,
              }}
              loading={loading || isConfirmAttachmentsLoading}
              title={i18n.t('components.uploadFiles.modalTitle')}
              onCancel={openConfirmCloseModal}
              size={ModalSize.LG}
              footer={(updateUserEmail): ReactNode => (
                <FileUploadModalFooter
                  ref={isMobileView && hasAnchorRef ? anchorBoxRef : null}
                  onCancel={openConfirmCloseModal}
                  handleFileChange={({ target: { files } }): Promise<void> =>
                    uploadAttachments(files).then(() => {
                      // Has an anchor only if you add a new file inside the modal
                      setHasAnchorRef(true);
                    })
                  }
                  saveChanges={(): Promise<void> =>
                    updateUserEmail ? updateUserEmail().then(onFilesUpload) : onFilesUpload()
                  }
                />
              )}
            >
              {(isUploadAttachmentsLoading || loading) && (
                <Loader classes={{ shadow: localClasses.shadow }} fullScreen checkChildren={false} />
              )}
              <UploadFileList
                ref={!isMobileView && hasAnchorRef ? anchorBoxRef : null}
                preselectedTag={preselectedTag}
                patientFullName={patientFullName}
              />
            </UploadFileModal>
          )}
        </>
      )}
    </>
  );
};

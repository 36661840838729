import { Modal, StatusCard } from '@lib/react-components';
import { Button, Stack } from '@mui/material';

import { i18n } from 'i18n';

interface IConfirmCloseModalProps {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

export const ConfirmCloseModal = ({ onConfirm, onCancel }: IConfirmCloseModalProps): JSX.Element => (
  <Modal
    onCancel={onCancel}
    title={i18n.t('features.attachments.confirmCloseModal.title')}
    footer={
      <Stack direction="row" spacing="14px">
        <Button onClick={onConfirm}>{i18n.t('general.buttons.confirm')}</Button>
        <Button variant="secondary" onClick={onCancel}>
          {i18n.t('general.buttons.cancel')}
        </Button>
      </Stack>
    }
  >
    <StatusCard text={i18n.t('features.attachments.confirmCloseModal.info')} />
  </Modal>
);

import { useDispatch } from 'react-redux';

import { useGenerateAndSavePdfPatientFile } from './useGenerateAndSavePdfPatientFile';

import { GenerateAndStorePdfPatientFileFileType } from '__generated__/types';
import { patientModel } from 'features/Patient/model';
import { IAttachmentFile } from 'graphql/types/files';
import { IReportPrescriptionArgs } from 'graphql/types/request';
import { IActionOptions } from 'store';
import { updatePatientFileList } from 'store/patient/actionCreators';

type AttachReportPrescriptionReturn = (
  pdfData: IReportPrescriptionArgs,
  options: IActionOptions,
  otpCode: string
) => void;

export const useAttachReportPrescription = (): AttachReportPrescriptionReturn => {
  const dispatch = useDispatch();
  const { patientInstitutionUser } = patientModel.usePatientStore();
  const generateAndSavePdfPatientFile = useGenerateAndSavePdfPatientFile();

  return (pdfData, options, otpCode) => {
    generateAndSavePdfPatientFile({
      variables: {
        otpCode,
        subjects: pdfData.subjects || [''],
        title: pdfData.title || '',
        fileType:
          // TODO: try and remove type assertionn - see todo in IReportPrescriptionArgs.fileType
          (pdfData.fileType as unknown as GenerateAndStorePdfPatientFileFileType) ||
          GenerateAndStorePdfPatientFileFileType.PRESCRIPTION,
        patientId: patientInstitutionUser?.patient?.id || '',
      },
      onError: options?.onError,
      onCompleted: data => {
        dispatch(updatePatientFileList(data.generateAndSavePdfPatientFile as IAttachmentFile));
        options?.onSuccess?.();
      },
    });
  };
};

import { Maybe } from '@lib/utils';
import { ReactNode, useRef, useState } from 'react';

import { GetFileInputHandler } from './GetFileInputHandler';
import { UploadFilesProps, UploadNewFileContext } from './UploadNewFileContext';

import { useAttachmentsStore } from 'features/Attachments';
import { UploadFiles } from 'features/Attachments/components/UploadFiles';

type UploadNewFileProviderProps = {
  children: ReactNode;
};

export const UploadNewFileProvider = ({ children }: UploadNewFileProviderProps): JSX.Element => {
  const [uploadFileProps, setUploadFileProps] = useState<Maybe<UploadFilesProps>>(null);
  const { setAttachmentsLoading } = useAttachmentsStore();
  const uploadNewFilesRef = useRef<Maybe<HTMLInputElement>>(null);

  const uploadNewFileHandler = (): void => {
    if (uploadNewFilesRef.current) {
      uploadNewFilesRef.current.click();
    }
  };

  return (
    <UploadNewFileContext.Provider
      value={{
        uploadFileProps,
        setUploadFileProps,
        uploadNewFileHandler,
      }}
    >
      <UploadFiles
        fileInput={GetFileInputHandler(uploadNewFilesRef)}
        loadingAction={setAttachmentsLoading}
        {...uploadFileProps}
      />
      {children}
    </UploadNewFileContext.Provider>
  );
};

import { Maybe } from '@lib/utils';
import { createContext } from 'react';

import { IUploadFilesProps } from 'features/Attachments/components/UploadFiles/UploadFiles';

export type UploadFilesProps = Omit<IUploadFilesProps, 'fileInput' | 'loadingAction'>;

type UploadNewFileContextType = {
  uploadFileProps: Maybe<UploadFilesProps>;
  setUploadFileProps: (props: UploadFilesProps) => void;
  uploadNewFileHandler: VoidFunction;
};

const defaultValue: UploadNewFileContextType = {
  uploadFileProps: null,
  setUploadFileProps: (): void => {},
  uploadNewFileHandler: (): void => {},
};

export const UploadNewFileContext = createContext<UploadNewFileContextType>(defaultValue);

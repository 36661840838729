import { MaterialSymbolsIcon, TimeIcon } from '@lib/icons';
import { Modal } from '@lib/react-components';
import { useExecuteOnTimeout } from '@lib/utils';
import { Stack, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { useStyles } from './styles';

import { i18n } from 'i18n';

interface IAiParamsExtractionTimeoutProps {
  onAiParamsExtractionTimeoutClose: VoidFunction;
}

const MODAL_DISAPPEAR_TIMEOUT = 5000;

export const AiParamsExtractionTimeoutModal = ({
  onAiParamsExtractionTimeoutClose,
}: IAiParamsExtractionTimeoutProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { palette } = useTheme();
  useExecuteOnTimeout(onAiParamsExtractionTimeoutClose, MODAL_DISAPPEAR_TIMEOUT);

  return (
    <Modal classes={{ paper: localClasses.modal, content: localClasses.modalContent }} isDraggable={false}>
      <Stack padding="55px 50px">
        <MaterialSymbolsIcon
          sx={{ marginBottom: 30 }}
          fill={palette.yellow.main}
          fontSize={70}
          icon={<TimeIcon fontWeight="300" />}
        />
        <Typography variant="h3" textAlign="center" marginBottom="14px">
          {i18n.t('features.attachments.uploadFiles.aiParamsExtractionTimeoutModal.title')}
        </Typography>
        <Typography variant="body1" textAlign="center">
          <Trans i18n={i18n} i18nKey="features.attachments.uploadFiles.aiParamsExtractionTimeoutModal.body" />
        </Typography>
      </Stack>
    </Modal>
  );
};

import { GlobalSearchDetailsDoctorsType } from '../api';

import { IGlobalSearchDetailsDoctorTabCard } from './types';

import { AllowDenyPolicyType } from '__generated__/types';
import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { canPatientCreateRequestForService } from 'features/Service/config/filterServices';

export const getDoctorTabData = (
  items?: GlobalSearchDetailsDoctorsType['globalSearchDetails']['doctors']['items']
): IGlobalSearchDetailsDoctorTabCard[] | undefined =>
  items?.map(
    ({
      serviceProvider: {
        doctorPortalUser: {
          id: portalUserId,
          user,
          institutionUser: { doctor },
        },
      },
      extendedServices,
      acceptsInfoRequest,
    }) => ({
      portalUserId,
      user,
      doctor: {
        slug: doctor?.slug || '',
        cvFileId: doctor?.cvFile?.id || '',
        specializations: doctor?.specializations.map(({ type }) => type),
        sphereOfInterest: doctor?.sphereOfInterest || [],
        acceptsInfoRequest,
        allowsOnDemandSchedule: extendedServices?.some(({ allowsOnDemandSchedule }) => allowsOnDemandSchedule),
        earliestAvailabilityDate: extendedServices
          ? globalSearchDetailsModel.getEarliestAvailabilityDate(extendedServices)
          : null,
        defaultPatientWritePolicy: doctor?.defaultPatientWritePolicy || AllowDenyPolicyType.DENY,
        patientWriteButtonVisibilityPolicy: doctor?.patientWriteButtonVisibilityPolicy || AllowDenyPolicyType.DENY,
      },
      services: extendedServices
        ? extendedServices.reduce<IGlobalSearchDetailsDoctorTabCard['services']>(
            (
              acc,
              {
                id,
                baseServiceType,
                title,
                price,
                bookingProviderExtension,
                patientBookingRequiresRequestProposal,
                earliestAvailabilityDate,
                allowsOnDemandSchedule,
              }
            ) =>
              canPatientCreateRequestForService({
                baseServiceType,
                patientBookingRequiresRequestProposal,
                earliestAvailabilityDate,
                allowsOnDemandSchedule,
              })
                ? [
                    ...acc,
                    {
                      id,
                      title,
                      price,
                      affiliationName:
                        bookingProviderExtension.__typename === 'AffiliationBookingProviderExtensionModel'
                          ? bookingProviderExtension.affiliationName
                          : undefined,
                      baseServiceType,
                    },
                  ]
                : acc,
            []
          )
        : [],
    })
  );

import { NotificationType, ShowNotification } from '@lib/core';

import {
  addPatientCalculations,
  setAbdomenUltrasoundMedicalDataParameter,
  setAllergyToHymenopteraMedicalDataParameter,
  setAnaphylacticShockMedicalDataParameter,
  setBoneDensitometryMedicalDataParameter,
  setBoneImagingMedicalDataParameter,
  setCharlsonIndexData,
  setCkitMutationMedicalDataParameter,
  setcTnmIndexData,
  setDipssIndexData,
  setDipssPlusIndexData,
  setEhrDataLoading,
  setELTSIndexData,
  setGastrointestinalDisordersMedicalDataParameter,
  setImmunophenotypingMedicalDataParameter,
  setIpssrIndexData,
  setIpssrMedicalIndexScore,
  setMipss70IndexData,
  setMipss70MedicalIndexScore,
  setMpn10IndexData,
  setMysecPmIndexData,
  setOtherAllergiesMedicalDataParameter,
  setPatientCalculations,
  setPatientCharlsonIndexScore,
  setPatientcTnmIndexScore,
  setPatientDipssIndexScore,
  setPatientDipssPlusIndexScore,
  setPatientELTSIndexScore,
  setPatientMastocytosisData,
  setPatientMastocytosisHistoryData,
  setPatientMpn10IndexScore,
  setPatientMysecPmIndexScore,
  setPatientpTnmIndexScore,
  setPatientSokalIndexScore,
  setPatientTnmIndexScore,
  setpTnmIndexData,
  setSkinInvolvementMedicalDataParameter,
  setSokalIndexData,
  setSyncopesMedicalDataParameter,
  setTnmIndexData,
  setTumoursMedicalDataParameter,
  updatePatientInfo,
} from './actionCreators';

import { Maybe } from '__generated__/types';
import { addAttachments } from 'features/Attachments/model/useAttachmentsStore';
import { UseAttachPatientFileReturn } from 'features/Attachments/model/useAttachPatientFile';
import { addChatFile } from 'features/Chat/model/useChatModelChatFilesStore';
import graphql, { graphQLMutate, graphQLQuery } from 'graphql/apollo';
import { ICompleteDicomDiskUploadData, ICompleteDicomDiskUploadVariables } from 'graphql/mutations/dicom/types';
import {
  IAddAllergiesAnaphylacticShockPatientMedicalData,
  IAddAllergiesAnaphylacticShockPatientMedicalDataVariables,
  IAddAllergiesHymenopteraPatientMedicalData,
  IAddAllergiesHymenopteraPatientMedicalDataVariables,
  IAddAllergiesOtherPatientMedicalData,
  IAddAllergiesOtherPatientMedicalDataVariables,
  IAddMedicalDataData,
  IAddMedicalDataVariable,
  IAddPathologyResultsCkitMutationPatientMedicalData,
  IAddPathologyResultsCkitMutationPatientMedicalDataVariables,
  IAddPathologyResultsImmunophenotypingPatientMedicalData,
  IAddPathologyResultsImmunophenotypingPatientMedicalDataVariables,
  IAddRadiologyAbdomenUltrasoundPatientMedicalData,
  IAddRadiologyAbdomenUltrasoundPatientMedicalDataVariables,
  IAddRadiologyBoneDensitometryPatientMedicalData,
  IAddRadiologyBoneDensitometryPatientMedicalDataVariables,
  IAddRadiologyBoneImagingPatientMedicalData,
  IAddRadiologyBoneImagingPatientMedicalDataVariables,
  IAddSignsAndSymptomsCommonPatientMedicalDataVariables,
  IAddSignsAndSymptomsGastrointestinalDisordersPatientMedicalData,
  IAddSignsAndSymptomsSkinInvolvementPatientMedicalData,
  IAddSignsAndSymptomsSyncopesPatientMedicalData,
  IAddTumoursGenericInformationPatientMedicalData,
  IAddTumoursGenericInformationPatientMedicalDataVariables,
  IEditPatientData,
  IEditPatientVariables,
  ISaveCharlsonScoreData,
  ISaveCharlsonScoreVariables,
  ISaveCtnmMedicalIndexCalculationData,
  ISaveCtnmMedicalIndexCalculationVariables,
  ISaveDipssMedicalIndexCalculationData,
  ISaveDipssMedicalIndexCalculationVariables,
  ISaveDipssPlusMedicalIndexCalculationData,
  ISaveDipssPlusMedicalIndexCalculationVariables,
  ISaveELTSScoreData,
  ISaveELTSScoreVariables,
  ISaveIpssrMedicalIndexCalculationData,
  ISaveIpssrMedicalIndexCalculationVariables,
  ISaveMipss70IndexCalculationData,
  ISaveMipss70IndexCalculationVariables,
  ISaveMpn10MedicalIndexCalculationData,
  ISaveMpn10MedicalIndexCalculationVariables,
  ISaveMysecPmMedicalIndexCalculationData,
  ISaveMysecPmMedicalIndexCalculationVariables,
  ISavePtnmMedicalIndexCalculationData,
  ISavePtnmMedicalIndexCalculationVariables,
  ISaveSokalScoreData,
  ISaveSokalScoreVariables,
  ISaveTnmMedicalIndexCalculationData,
  ISaveTnmMedicalIndexCalculationVariables,
} from 'graphql/mutations/patient/types';
import {
  ICharlsonMedicalIndexParametersVariables,
  ICtnmMedicalIndexCalculationData,
  ICtnmMedicalIndexCalculationVariables,
  IDipssMedicalIndexCalculationData,
  IDipssMedicalIndexCalculationVariables,
  IDipssPlusMedicalIndexCalculationData,
  IDipssPlusMedicalIndexCalculationVariables,
  IELTSMedicalIndexParametersVariables,
  IGetAllergiesAnaphylacticShockPatientMedicalDataHistoryData,
  IGetAllergiesAnaphylacticShockPatientMedicalDataHistoryVariables,
  IGetAllergiesHymenopteraPatientMedicalDataHistoryData,
  IGetAllergiesHymenopteraPatientMedicalDataHistoryVariables,
  IGetAllergiesOtherPatientMedicalDataHistoryData,
  IGetAllergiesOtherPatientMedicalDataHistoryVariables,
  IGetCharlsonIndexScoreData,
  IGetELTSIndexScoreData,
  IGetMastocytosisPatientMedicalData,
  IGetMastocytosisPatientMedicalDataVariables,
  IGetMedicalDataByPatientData,
  IGetMedicalDataByPatientVariables,
  IGetPathologyResultsCKitMutationPatientMedicalDataHistoryData,
  IGetPathologyResultsImmunophenotypingPatientMedicalDataHistoryData,
  IGetPathologyResultsPatientMedicalDataHistoryCommonVariables,
  IGetPatientCardHeaderData,
  IGetPatientCardHeaderVariables,
  IGetRadiologyAbdomenUltrasoundPatientMedicalDataHistoryData,
  IGetRadiologyBoneDensitometryPatientMedicalDataHistoryData,
  IGetRadiologyBoneImagingPatientMedicalDataHistoryData,
  IGetRadiologyPatientMedicalDataHistoryCommonVariables,
  IGetSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistoryData,
  IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables,
  IGetSignsAndSymptomsSkinInvolvementPatientMedicalDataHistoryData,
  IGetSignsAndSymptomsSyncopesPatientMedicalDataHistoryData,
  IGetSokalIndexScoreData,
  IGetTumoursGenericInformationPatientMedicalDataHistoryData,
  IGetTumoursGenericInformationPatientMedicalDataHistoryVariables,
  IIpssrMedicalIndexCalculationData,
  IIpssrMedicalIndexCalculationVariables,
  IMipss70IndexCalculationData,
  IMipss70IndexCalculationVariables,
  IMpn10MedicalIndexCalculationData,
  IMpn10MedicalIndexCalculationVariables,
  IMysecPmMedicalIndexCalculationData,
  IMysecPmMedicalIndexCalculationVariables,
  IPatientCalculationsData,
  IPatientCalculationsVariables,
  IPtnmMedicalIndexCalculationData,
  IPtnmMedicalIndexCalculationVariables,
  ISokalMedicalIndexParametersVariables,
  ITnmMedicalIndexCalculationData,
  ITnmMedicalIndexCalculationVariables,
} from 'graphql/queries/patient/types';
import {
  ICharlsonIndexCalculationParameters,
  IELTSIndexCalculationParameters,
  ISokalIndexCalculationParameters,
} from 'graphql/types/patient';
import { IServiceAttachmentFile } from 'graphql/types/request';
import { i18n } from 'i18n';
import { validateDate } from 'lib/validation';
import { IActionOptions } from 'store';
import { addIntramedFile } from 'store/intramed/actionCreators';
import { setPatientMedicalData } from 'store/patient/actionCreators';
import { StoreAsyncHandler } from 'store/types';

type CreateDicomFileActionParams = {
  uploadId: string;
  dateOfExecution: string;
  label: string;
  showNotification: ShowNotification;
  patientId?: Maybe<string>;
  needAttachToIntramed?: boolean;
  needAttachToRequest?: boolean;
  attachToChat?: boolean;
  threadId?: string;
  attachPatientFile: UseAttachPatientFileReturn;
};

export const createDicomFileAction =
  ({
    uploadId,
    dateOfExecution,
    label,
    showNotification,
    patientId,
    needAttachToRequest,
    needAttachToIntramed,
    attachToChat,
    threadId,
    attachPatientFile,
  }: CreateDicomFileActionParams): StoreAsyncHandler =>
  async dispatch => {
    validateDate(dateOfExecution, 'createDicomFileAction');

    try {
      const { data } = await graphQLMutate<ICompleteDicomDiskUploadData, ICompleteDicomDiskUploadVariables>({
        mutation: graphql.mutations.dicom.COMPLETE_DICOM_DISK_UPLOAD,
        variables: { dicomDiskId: uploadId, dateOfExecution, label },
      });

      if (!data) {
        showNotification(i18n.t('notifications.file.loadingError', { label: dateOfExecution }), NotificationType.FAIL);

        return;
      }

      if (needAttachToIntramed) {
        dispatch(
          addIntramedFile({
            file: { ...data.completeDicomDiskUpload },
            id: data.completeDicomDiskUpload.id,
            attachedAt: '',
          })
        );
      }

      if (attachToChat) {
        addChatFile({
          file: { ...data.completeDicomDiskUpload },
          id: data.completeDicomDiskUpload.id,
          attachedAt: '',
        });
      }

      if (patientId) {
        attachPatientFile({
          variables: {
            fileId: data.completeDicomDiskUpload.id,
            patientId,
          },
        });
      }

      if (needAttachToRequest) {
        addAttachments([
          {
            threadId,
            id: data.completeDicomDiskUpload.id,
            file: { ...data.completeDicomDiskUpload },
          } as IServiceAttachmentFile,
        ]);
      }
    } catch (error) {
      showNotification(i18n.t('notifications.file.loadingError', { label }), NotificationType.FAIL);
    }
  };

export const getMedicalDataByPatientAction =
  (
    variables: IGetMedicalDataByPatientVariables,
    { showNotification, onSuccess }: IActionOptions,
    withLoader = true
  ): StoreAsyncHandler =>
  async dispatch => {
    if (withLoader) {
      dispatch(setEhrDataLoading(true));
    }

    const loadPatientHeaderData = async (): Promise<void> => {
      const { data: patientData } = await graphQLQuery<IGetPatientCardHeaderData, IGetPatientCardHeaderVariables>({
        query: graphql.queries.patient.GET_PATIENT_CARD_HEADER,
        variables: { patient: variables.patientId },
      });

      if (patientData) {
        dispatch(
          setPatientMedicalData({
            id: '',
            patient: { ...patientData.patient, fileList: [] },
            familyHistory: null,
            psychologicalHistory: null,
            vitalParameter: null,
            currentMedication: null,
          })
        );
        // TODO: refactor to avoid duplication of onSuccess call
        onSuccess?.();
      }
    };

    try {
      const { data } = await graphQLQuery<IGetMedicalDataByPatientData, IGetMedicalDataByPatientVariables>({
        query: graphql.queries.patient.GET_MEDICAL_DATA_BY_PATIENT,
        variables,
      });

      if (!data) {
        await loadPatientHeaderData();
      }

      if (data) {
        dispatch(setPatientMedicalData(data.medicalData));
        // TODO: refactor to avoid duplication of onSuccess call
        onSuccess?.();
      }
    } catch (_) {
      try {
        await loadPatientHeaderData();
      } catch (error) {
        if (error && showNotification) {
          showNotification(i18n.t('general.notifications.patientCareTeamLoadError'), NotificationType.FAIL);
        }
      }
    }

    dispatch(setEhrDataLoading(false));
  };

export const addMedicalDataByIdAction =
  (variables: IAddMedicalDataVariable, { showNotification, onSuccess }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.vitalParameter?.evaluatedAt, 'addMedicalDataByIdAction');

    try {
      const { data } = await graphQLMutate<IAddMedicalDataData, IAddMedicalDataVariable>({
        mutation: graphql.mutations.patient.ADD_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientMedicalData(data.updatePatientMedicalData));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error && showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const editPatientAction =
  (variables: IEditPatientVariables, { showNotification, onSuccess }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLMutate<IEditPatientData, IEditPatientVariables>({
        mutation: graphql.mutations.patient.EDIT_PATIENT,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(updatePatientInfo(data.updatePatientPersonalInfo));
      onSuccess?.();
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getPatientMastocytosisDataAction =
  (variables: IGetMastocytosisPatientMedicalDataVariables, { showNotification }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetMastocytosisPatientMedicalData,
        IGetMastocytosisPatientMedicalDataVariables
      >({
        query: graphql.queries.patient.GET_MASTOCYTOSIS_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientMastocytosisData(data.getMastocytosisPatientMedicalData));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addTumoursMedicalDataAction =
  (
    variables: IAddTumoursGenericInformationPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddTumoursGenericInformationPatientMedicalData,
        IAddTumoursGenericInformationPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setTumoursMedicalDataParameter(data.addTumoursGenericInformationPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getTumoursHistoryDataAction =
  (
    variables: IGetTumoursGenericInformationPatientMedicalDataHistoryVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetTumoursGenericInformationPatientMedicalDataHistoryData,
        IGetTumoursGenericInformationPatientMedicalDataHistoryVariables
      >({
        query: graphql.queries.patient.GET_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          tumours: {
            genericInformation: data.getTumoursGenericInformationPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addAnaphylacticShockMedicalDataAction =
  (
    variables: IAddAllergiesAnaphylacticShockPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddAllergiesAnaphylacticShockPatientMedicalData,
        IAddAllergiesAnaphylacticShockPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setAnaphylacticShockMedicalDataParameter(data.addAllergiesAnaphylacticShockPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getAnaphylacticShockHistoryDataAction =
  (
    variables: IGetAllergiesAnaphylacticShockPatientMedicalDataHistoryVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetAllergiesAnaphylacticShockPatientMedicalDataHistoryData,
        IGetAllergiesAnaphylacticShockPatientMedicalDataHistoryVariables
      >({
        query: graphql.queries.patient.GET_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          allergies: {
            anaphylacticShock: data.getAllergiesAnaphylacticShockPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addAllergyToHymenopteraMedicalDataAction =
  (
    variables: IAddAllergiesHymenopteraPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddAllergiesHymenopteraPatientMedicalData,
        IAddAllergiesHymenopteraPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setAllergyToHymenopteraMedicalDataParameter(data.addAllergiesHymenopteraPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getAllergyToHymenopteraHistoryDataAction =
  (
    variables: IGetAllergiesHymenopteraPatientMedicalDataHistoryVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetAllergiesHymenopteraPatientMedicalDataHistoryData,
        IGetAllergiesHymenopteraPatientMedicalDataHistoryVariables
      >({
        query: graphql.queries.patient.GET_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          allergies: {
            allergyToHymenoptera: data.getAllergiesHymenopteraPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addOtherAllergiesMedicalDataAction =
  (variables: IAddAllergiesOtherPatientMedicalDataVariables, { showNotification }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddAllergiesOtherPatientMedicalData,
        IAddAllergiesOtherPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_ALLERGIES_OTHER_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setOtherAllergiesMedicalDataParameter(data.addAllergiesOtherPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getOtherAllergiesHistoryDataAction =
  (
    variables: IGetAllergiesOtherPatientMedicalDataHistoryVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetAllergiesOtherPatientMedicalDataHistoryData,
        IGetAllergiesOtherPatientMedicalDataHistoryVariables
      >({
        query: graphql.queries.patient.GET_ALLERGIES_OTHER_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          allergies: {
            otherAllergies: data.getAllergiesOtherPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addSyncopesMedicalDataAction =
  (
    variables: IAddSignsAndSymptomsCommonPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddSignsAndSymptomsSyncopesPatientMedicalData,
        IAddSignsAndSymptomsCommonPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_SIGNS_AND_SYMPTOMPS_SYNCOPES_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setSyncopesMedicalDataParameter(data.addSignsAndSymptomsSyncopesPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getSyncopesHistoryDataAction =
  (
    variables: IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetSignsAndSymptomsSyncopesPatientMedicalDataHistoryData,
        IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_SIGNS_AND_SYMPTOMS_SYNCOPES_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          signsAndSymptoms: {
            syncopes: data.getSignsAndSymptomsSyncopesPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addGastrointestinalDisordersMedicalDataAction =
  (
    variables: IAddSignsAndSymptomsCommonPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddSignsAndSymptomsGastrointestinalDisordersPatientMedicalData,
        IAddSignsAndSymptomsCommonPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_SIGNS_AND_SYMPTOMPS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setGastrointestinalDisordersMedicalDataParameter(
          data.addSignsAndSymptomsGastrointestinalDisordersPatientMedicalData.data
        )
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getGastrointestinalDisordersHistoryDataAction =
  (
    variables: IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistoryData,
        IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          signsAndSymptoms: {
            gastrointestinalDisorders: data.getSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addSkinInvolvementMedicalDataAction =
  (
    variables: IAddSignsAndSymptomsCommonPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddSignsAndSymptomsSkinInvolvementPatientMedicalData,
        IAddSignsAndSymptomsCommonPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_SIGNS_AND_SYMPTOMPS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setSkinInvolvementMedicalDataParameter(data.addSignsAndSymptomsSkinInvolvementPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getSkinInvolvementHistoryDataAction =
  (
    variables: IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetSignsAndSymptomsSkinInvolvementPatientMedicalDataHistoryData,
        IGetSignsAndSymptomsPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          signsAndSymptoms: {
            skinInvolvement: data.getSignsAndSymptomsSkinInvolvementPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addBoneDensitometryMedicalDataAction =
  (
    variables: IAddRadiologyBoneDensitometryPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddRadiologyBoneDensitometryPatientMedicalData,
        IAddRadiologyBoneDensitometryPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setBoneDensitometryMedicalDataParameter(data.addRadiologyBoneDensitometryPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getBoneDensitometryHistoryDataAction =
  (
    variables: IGetRadiologyPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetRadiologyBoneDensitometryPatientMedicalDataHistoryData,
        IGetRadiologyPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          radiology: {
            boneDensitometry: data.getRadiologyBoneDensitometryPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addBoneImagingMedicalDataAction =
  (
    variables: IAddRadiologyBoneImagingPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddRadiologyBoneImagingPatientMedicalData,
        IAddRadiologyBoneImagingPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setBoneImagingMedicalDataParameter(data.addRadiologyBoneImagingPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getBoneImagingHistoryDataAction =
  (
    variables: IGetRadiologyPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetRadiologyBoneImagingPatientMedicalDataHistoryData,
        IGetRadiologyPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          radiology: {
            boneImaging: data.getRadiologyBoneImagingPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addAbdomenUltrasoundMedicalDataAction =
  (
    variables: IAddRadiologyAbdomenUltrasoundPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddRadiologyAbdomenUltrasoundPatientMedicalData,
        IAddRadiologyAbdomenUltrasoundPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setAbdomenUltrasoundMedicalDataParameter(data.addRadiologyAbdomenUltrasoundPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getAbdomenUltrasoundHistoryDataAction =
  (
    variables: IGetRadiologyPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetRadiologyAbdomenUltrasoundPatientMedicalDataHistoryData,
        IGetRadiologyPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          radiology: {
            abdomenUltrasound: data.getRadiologyAbdomenUltrasoundPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addImmunophenotypingMedicalDataAction =
  (
    variables: IAddPathologyResultsImmunophenotypingPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddPathologyResultsImmunophenotypingPatientMedicalData,
        IAddPathologyResultsImmunophenotypingPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setImmunophenotypingMedicalDataParameter(data.addPathologyResultsImmunophenotypingPatientMedicalData.data)
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getImmunophenotypingHistoryDataAction =
  (
    variables: IGetPathologyResultsPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetPathologyResultsImmunophenotypingPatientMedicalDataHistoryData,
        IGetPathologyResultsPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          pathologyResults: {
            immunophenotyping: data.getPathologyResultsImmunophenotypingPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const addCkitMutationMedicalDataAction =
  (
    variables: IAddPathologyResultsCkitMutationPatientMedicalDataVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IAddPathologyResultsCkitMutationPatientMedicalData,
        IAddPathologyResultsCkitMutationPatientMedicalDataVariables
      >({
        query: graphql.mutations.patient.ADD_PATHOLOGY_RESULTS_C_KIT_MUTATION_PATIENT_MEDICAL_DATA,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setCkitMutationMedicalDataParameter(data.addPathologyResultsCKitMutationPatientMedicalData.data));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getCkitMutationHistoryDataAction =
  (
    variables: IGetPathologyResultsPatientMedicalDataHistoryCommonVariables,
    { showNotification }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IGetPathologyResultsCKitMutationPatientMedicalDataHistoryData,
        IGetPathologyResultsPatientMedicalDataHistoryCommonVariables
      >({
        query: graphql.queries.patient.GET_PATHOLOGY_RESULTS_C_KIT_PATIENT_MEDICAL_DATA_HISTORY,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(
        setPatientMastocytosisHistoryData({
          pathologyResults: {
            cKitMutation: data.getPathologyResultsCKitMutationPatientMedicalDataHistory,
          },
        })
      );
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateSokalIndexScoreAction =
  (
    variables: ISokalMedicalIndexParametersVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IGetSokalIndexScoreData, ISokalMedicalIndexParametersVariables>({
        query: graphql.queries.patient.CALCULATE_SOKAL_INDEX_SCORE,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientSokalIndexScore(data.sokalMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateELTSIndexScoreAction =
  (
    variables: IELTSMedicalIndexParametersVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IGetELTSIndexScoreData, IELTSMedicalIndexParametersVariables>({
        query: graphql.queries.patient.CALCULATE_ELTS_INDEX_SCORE,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientELTSIndexScore(data.eltsMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateMpn10IndexScoreAction =
  (
    variables: IMpn10MedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IMpn10MedicalIndexCalculationData, IMpn10MedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_MPN10_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientMpn10IndexScore(data.mpn10MedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateDipssPlusIndexScoreAction =
  (
    variables: IDipssPlusMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IDipssPlusMedicalIndexCalculationData,
        IDipssPlusMedicalIndexCalculationVariables
      >({
        query: graphql.queries.patient.GET_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientDipssPlusIndexScore(data.dipssPlusMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateIpssrMedicalIndexScoreAction =
  (
    variables: IIpssrMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IIpssrMedicalIndexCalculationData, IIpssrMedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_IPSSR_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setIpssrMedicalIndexScore(data.ipssrMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateMipss70IndexScoreAction =
  (variables: IMipss70IndexCalculationVariables, { showNotification, onSuccess }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IMipss70IndexCalculationData, IMipss70IndexCalculationVariables>({
        query: graphql.queries.patient.GET_MIPSS_70_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setMipss70MedicalIndexScore(data.mipss70MedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateCharlsonIndexScoreAction =
  (
    variables: ICharlsonMedicalIndexParametersVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IGetCharlsonIndexScoreData, ICharlsonMedicalIndexParametersVariables>({
        query: graphql.queries.patient.CALCULATE_CHARLSON_INDEX_SCORE,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientCharlsonIndexScore(data.cciMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateMysecPmIndexScoreAction =
  (
    variables: IMysecPmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<
        IMysecPmMedicalIndexCalculationData,
        IMysecPmMedicalIndexCalculationVariables
      >({
        query: graphql.queries.patient.MYSEC_PM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientMysecPmIndexScore(data.mysecPmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateDipssIndexScoreAction =
  (
    variables: IDipssMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IDipssMedicalIndexCalculationData, IDipssMedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_DIPSS_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientDipssIndexScore(data.dipssMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculatecTnmIndexScoreAction =
  (
    variables: ICtnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<ICtnmMedicalIndexCalculationData, ICtnmMedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_CTNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientcTnmIndexScore(data.ctnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculatepTnmIndexScoreAction =
  (
    variables: IPtnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IPtnmMedicalIndexCalculationData, IPtnmMedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_PTNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientpTnmIndexScore(data.ptnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const calculateTnmIndexScoreAction =
  (
    variables: ITnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess, onError }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<ITnmMedicalIndexCalculationData, ITnmMedicalIndexCalculationVariables>({
        query: graphql.queries.patient.GET_TNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        if (onError) {
          onError();
        }

        return;
      }

      dispatch(setPatientTnmIndexScore(data.tnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveSokalIndexScoreAction =
  (
    sokalMedicalIndexParametersModel: ISokalIndexCalculationParameters,
    dateOfExecution: string,
    patientId: string,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(dateOfExecution, 'saveSokalIndexScoreAction');

    try {
      const { data } = await graphQLQuery<ISaveSokalScoreData, ISaveSokalScoreVariables>({
        query: graphql.mutations.patient.SAVE_SOKAL_INDEX,
        variables: {
          patientId,
          dateOfExecution,
          sokalMedicalIndexParametersModel,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setSokalIndexData(data.saveSokalMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveSokalMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveMpn10IndexScoreAction =
  (
    { patientId, dateOfExecution, mpn10MedicalIndexParametersModel }: ISaveMpn10MedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(dateOfExecution, 'saveMpn10IndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveMpn10MedicalIndexCalculationData,
        ISaveMpn10MedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_MPN10_MEDICAL_INDEX_CALCULATION,
        variables: {
          patientId,
          dateOfExecution,
          mpn10MedicalIndexParametersModel,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setMpn10IndexData(data.saveMpn10MedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveMpn10MedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveDipssPlusIndexScoreAction =
  (
    {
      patientId,
      dateOfExecution,
      dipssPlusMedicalIndexParametersModel,
    }: ISaveDipssPlusMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(dateOfExecution, 'saveDipssPlusIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveDipssPlusMedicalIndexCalculationData,
        ISaveDipssPlusMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION,
        variables: {
          patientId,
          dateOfExecution,
          dipssPlusMedicalIndexParametersModel,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setDipssPlusIndexData(data.saveDipssPlusMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveDipssPlusMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveIpssrMedicalIndexCalculationAction =
  (
    variables: ISaveIpssrMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'saveIpssrMedicalIndexCalculationAction');

    try {
      const { data } = await graphQLMutate<
        ISaveIpssrMedicalIndexCalculationData,
        ISaveIpssrMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_IPSSR_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setIpssrIndexData(data.saveIpssrMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveIpssrMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveMipss70IndexCalculationAction =
  (
    variables: ISaveMipss70IndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'saveMipss70IndexCalculationAction');

    try {
      const { data } = await graphQLMutate<ISaveMipss70IndexCalculationData, ISaveMipss70IndexCalculationVariables>({
        mutation: graphql.mutations.patient.SAVE_MIPSS_70_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setMipss70IndexData(data.saveMipss70MedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveMipss70MedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveELTSIndexScoreAction =
  (
    variables: IELTSIndexCalculationParameters,
    date: Date,
    patientId: string,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<ISaveELTSScoreData, ISaveELTSScoreVariables>({
        query: graphql.mutations.patient.SAVE_ELTS_INDEX,
        variables: {
          patientId,
          dateOfExecution: date.toISOString(),
          eltsMedicalIndexParametersModel: variables,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setELTSIndexData(data.saveEltsMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveEltsMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveCharlsonIndexScoreAction =
  (
    variables: ICharlsonIndexCalculationParameters,
    date: Date,
    patientId: string,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<ISaveCharlsonScoreData, ISaveCharlsonScoreVariables>({
        query: graphql.mutations.patient.SAVE_CHARLSON_INDEX,
        variables: {
          patientId,
          dateOfExecution: date.toISOString(),
          cciMedicalIndexParametersModel: variables,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setCharlsonIndexData(data.saveCciMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveCciMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveMysecPmIndexScoreAction =
  (
    variables: ISaveMysecPmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'saveMysecPmIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveMysecPmMedicalIndexCalculationData,
        ISaveMysecPmMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_MYSEC_PM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setMysecPmIndexData(data.saveMysecPmMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveMysecPmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const savecTnmIndexScoreAction =
  (
    variables: ISaveCtnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'saveCTnmIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveCtnmMedicalIndexCalculationData,
        ISaveCtnmMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_CTNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setcTnmIndexData(data.saveCtnmMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveCtnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const savepTnmIndexScoreAction =
  (
    variables: ISavePtnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'savepTnmIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISavePtnmMedicalIndexCalculationData,
        ISavePtnmMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_PTNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setpTnmIndexData(data.savePtnmMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.savePtnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveTnmIndexScoreAction =
  (
    variables: ISaveTnmMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(variables.dateOfExecution, 'saveTnmIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveTnmMedicalIndexCalculationData,
        ISaveTnmMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_TNM_MEDICAL_INDEX_CALCULATION,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setTnmIndexData(data.saveTnmMedicalIndexCalculation));
      dispatch(addPatientCalculations(data.saveTnmMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const saveDipssIndexScoreAction =
  (
    { patientId, dateOfExecution, dipssMedicalIndexParametersModel }: ISaveDipssMedicalIndexCalculationVariables,
    { showNotification, onSuccess }: IActionOptions
  ): StoreAsyncHandler =>
  async dispatch => {
    validateDate(dateOfExecution, 'saveDipssIndexScoreAction');

    try {
      const { data } = await graphQLMutate<
        ISaveDipssMedicalIndexCalculationData,
        ISaveDipssMedicalIndexCalculationVariables
      >({
        mutation: graphql.mutations.patient.SAVE_DIPSS_MEDICAL_INDEX_CALCULATION,
        variables: {
          patientId,
          dateOfExecution,
          dipssMedicalIndexParametersModel,
        },
      });

      if (!data) {
        return;
      }

      dispatch(setDipssIndexData(data.saveDipssMedicalIndexCalculation));

      dispatch(addPatientCalculations(data.saveDipssMedicalIndexCalculation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

export const getPatientCalculationsAction =
  (variables: IPatientCalculationsVariables, { showNotification }: IActionOptions): StoreAsyncHandler =>
  async dispatch => {
    try {
      const { data } = await graphQLQuery<IPatientCalculationsData, IPatientCalculationsVariables>({
        query: graphql.queries.patient.PATIENT_CALCULATIONS,
        variables,
      });

      if (!data) {
        return;
      }

      dispatch(setPatientCalculations(data.patientCalculations));
    } catch (error) {
      if (showNotification) {
        showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
      }
    }
  };

import { IDefaultEmptyValidationRule } from './types';

import { i18n } from 'i18n';

export const REQUIRED_FIELD_ERROR_MESSAGE = `^${i18n.t('components.forms.validationErrors.requiredField')}`;

export const defaultEmptyValidationRule: IDefaultEmptyValidationRule = {
  presence: { allowEmpty: false, message: REQUIRED_FIELD_ERROR_MESSAGE },
};

export const emptyValidationRule: IDefaultEmptyValidationRule = {
  presence: { allowEmpty: true, message: REQUIRED_FIELD_ERROR_MESSAGE },
};

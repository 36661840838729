import { ChatMenuItemType } from './chatMenuItemType';

import { i18n } from 'i18n';

export const CHAT_MENU_ITEMS_CONFIG: Record<ChatMenuItemType, string> = {
  [ChatMenuItemType.SelectFromClinicalFolder]: i18n.t('features.chat.menuItems.selectFromClinicalFolder'),
  [ChatMenuItemType.UploadNewFile]: i18n.t('features.chat.menuItems.uploadNewFile'),
  [ChatMenuItemType.UploadDICOM]: i18n.t('features.chat.menuItems.uploadDICOM'),
  [ChatMenuItemType.ScanDocument]: i18n.t('features.chat.menuItems.scanDocument'),
  [ChatMenuItemType.UploadDocuments]: i18n.t('features.chat.menuItems.uploadDocuments'),
  [ChatMenuItemType.UploadFoto]: i18n.t('features.chat.menuItems.uploadFoto'),
};

const CHAT_MENU_ITEMS_POSITIONS: ChatMenuItemType[] = [
  ChatMenuItemType.ScanDocument,
  ChatMenuItemType.UploadDocuments,
  ChatMenuItemType.UploadFoto,
  ChatMenuItemType.UploadNewFile,
  ChatMenuItemType.UploadDICOM,
  ChatMenuItemType.SelectFromClinicalFolder,
];

export const getChatMenuItemPosition = (item: ChatMenuItemType): number =>
  CHAT_MENU_ITEMS_POSITIONS.findIndex(chatMenuItem => chatMenuItem === item);

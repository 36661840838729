import { useNotifications } from '@lib/core';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useAttachReportPrescription } from './useAttachReportPrescription';
import { usePrefilledValueControls } from './usePrefilledValueControls';
import { useUpdatePatientFileReportPrescription } from './useUpdatePatientFileReportPrescription';
import { useUpdateReportPrescriptionDataInStore } from './useUpdateReportPrescriptionDataInStore';

import { PDFDataAdditionType, PDFDataStorageType } from '__generated__/types';
import { useFetchMedicalData } from 'features/MedicalData/hooks';
import { MedicalReportFormValues } from 'features/MedicalReports';
import { patientModel } from 'features/Patient/model';
import { useDrafts } from 'features/Root/ui/MedicalDBProvider';

interface IUseSubmitPdfDataParams<T extends MedicalReportFormValues = MedicalReportFormValues> {
  fileType: PDFDataStorageType;
  handleSubjects: (values: T) => string[];
  isInvalidated?: boolean;
}

export interface IUseSubmitPdfDataReturn<T extends MedicalReportFormValues = MedicalReportFormValues> {
  onSubmit: (
    values: T,
    { resetForm }: Pick<FormikHelpers<T>, 'resetForm'>,
    otp: string,
    onSuccess?: VoidFunction
  ) => void;
  onResetModal: VoidFunction;
}

export const useSubmitPdfData = <T extends MedicalReportFormValues = MedicalReportFormValues>({
  fileType,
  handleSubjects,
  isInvalidated,
}: IUseSubmitPdfDataParams<T>): IUseSubmitPdfDataReturn<T> => {
  const { showNotification } = useNotifications();
  const { patientInstitutionUser: patient } = patientModel.usePatientStore();
  const [prefilledValue, setPrefilledValue] = usePrefilledValueControls();
  const sendData = useAttachReportPrescription();

  const { updatePatientFileReportPrescription } = useUpdatePatientFileReportPrescription();
  const { updateReportPrescriptionDataInStore } = useUpdateReportPrescriptionDataInStore();

  const fetchMedicalData = useFetchMedicalData();

  const { patientId } = useParams<{ patientId: string }>();

  const { drafts, deleteDraft, loadDrafts } = useDrafts();

  const onSubmit: IUseSubmitPdfDataReturn<T>['onSubmit'] = (values, { resetForm }, otp, onSuccess) => {
    const { title } = values;

    const subjects = handleSubjects(values);

    const commonData = {
      subjects,
      title,
      // @ts-expect-error TODO: fix typings
      multidisciplinaryTeam: values.multidisciplinaryTeam || [],
    };

    const onRemoveReportDraft = (): void => {
      if (
        (fileType === PDFDataStorageType.REPORT || fileType === PDFDataStorageType.DICOM_REPORT) &&
        drafts[patientId]
      ) {
        deleteDraft(patientId);
        loadDrafts(patientId);
      }
    };

    const commonOptions = {
      showNotification,
      onSuccess: (): void => {
        resetForm();
        setPrefilledValue(null);
        onSuccess?.();
        onRemoveReportDraft();
      },
      onError: (): void => console.error('error'),
    };

    if (!prefilledValue) {
      fetchMedicalData();

      return sendData(
        {
          fileType,
          ...commonData,
        },
        commonOptions,
        otp
      );
    }

    if (!prefilledValue.fileId || !prefilledValue.threadId) {
      return updateReportPrescriptionDataInStore({
        variables: {
          otpCode: otp,
          fileType,
          ...commonData,
          pdfData: prefilledValue.pdfDataId,
          pdfDataId: prefilledValue?.pdfDataId || '',
        },
        options: commonOptions,
      });
    }

    return updatePatientFileReportPrescription({
      variables: {
        ...commonData,
        otpCode: otp,
        file: prefilledValue.fileId,
        patient: patient?.patient?.id || prefilledValue.patient?.id || '',
        addition: {
          addedAt: new Date().toISOString(),
          subjects: commonData.subjects,
          type: PDFDataAdditionType.REPORT_ADDENDUM,
        },
        isInvalidated: !!isInvalidated,
      },
      options: commonOptions,
    });
  };

  const onResetModal: VoidFunction = () => {
    setPrefilledValue(null);
  };

  return {
    onSubmit,
    onResetModal,
  };
};

import {
  PrivaciesApiPendingPrivacies,
  PrivaciesApiPendingPrivaciesDocument,
  PrivaciesType,
  usePrivaciesApiPendingPrivacies,
  usePrivaciesApiUpdatePrivacyAcceptance,
  usePrivaciesModelStore,
} from '@lib/features-bll';
import { FormikProps, useFormik } from 'formik';

import { PrivacyType } from '__generated__/types';
import { i18n } from 'i18n';
import { validateUpdatePrivacies, UpdatePrivaciesError } from 'lib/helpers/validation/updatePrivaciesValidation';

const initialValues: Record<PrivacyType, boolean> = {
  TERMS_OF_USE: false,
  PRIVACY_POLICY: false,
  CODE_OF_MEDICAL_ETHICS: false,
  DOCTOR_PATIENT_CONTRACT: false,
  DATA_PROCESSING: false,
  EHR_CONSENSUS: false,
  NEWSLETTER_CONSENSUS: false,
  MARKETING_CLIENTS_1: false,
  MARKETING_CLIENTS_2: false,
  ITSALUTE_COLLABORATION: false,
  ITSALUTE_COLLABORATION2: false,
};

interface IUsePrivaciesModelUpdatePrivaciesModalProps {
  onSuccess: VoidFunction;
}

interface IUsePrivaciesModelUpdatePrivaciesModalReturn
  extends Pick<FormikProps<PrivaciesType>, 'values' | 'errors' | 'touched' | 'handleSubmit' | 'handleChange'> {
  privaciesData?: PrivaciesApiPendingPrivacies;
  isUpdatePrivacyAcceptanceSubmitting: boolean;
}

type UsePrivaciesModelUpdatePrivaciesModal = (
  props: IUsePrivaciesModelUpdatePrivaciesModalProps
) => IUsePrivaciesModelUpdatePrivaciesModalReturn;

export const usePrivaciesModelUpdatePrivaciesModal: UsePrivaciesModelUpdatePrivaciesModal = ({ onSuccess }) => {
  const { updatePrivacies } = usePrivaciesModelStore();
  const { data: privaciesData } = usePrivaciesApiPendingPrivacies({
    fetchPolicy: 'cache-first',
  });

  const [updatePrivacyAcceptance, { loading }] = usePrivaciesApiUpdatePrivacyAcceptance({
    refetchQueries: [PrivaciesApiPendingPrivaciesDocument],
    notifications: {
      onCompleted: i18n.t('components.forms.updatePrivaciesForm.notification.success') as string,
    },
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<PrivaciesType>({
    initialValues,
    validate: (validateValues): UpdatePrivaciesError | undefined =>
      validateUpdatePrivacies(validateValues, privaciesData?.pendingPrivacies),
    validateOnMount: true,
    onSubmit: async () => {
      const acceptedPrivacies =
        privaciesData?.pendingPrivacies.map(privacy => ({
          id: privacy.id,
          isAccepted: values[privacy.type] || false,
        })) || [];

      await updatePrivacyAcceptance({
        variables: {
          acceptedPrivacies,
        },
        onCompleted: data => {
          updatePrivacies(data.updatePrivacyAcceptance);

          onSuccess();
        },
      });
    },
  });

  return {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    privaciesData,
    isUpdatePrivacyAcceptanceSubmitting: loading,
  };
};

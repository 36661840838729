import { FileTag, Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { usePortalConfiguration } from 'features/Portal';

interface IAindoFile {
  fileTag?: Maybe<FileTag>;
  isPdfDataExtractable?: boolean;
  hasAnyPdfParametersRevision?: boolean;
}

interface IUseAindoFlowAvailabilityReturn {
  isAindoAvailable: boolean;
  getAindoAvailabilityByFile: (file: IAindoFile) => boolean;
  getAindoHeaderExtractionAvailabilityByFile: (file: IAindoFile) => boolean;
  getAindoAvailabilityByCompletionPercent: (
    completionPercent: number | undefined,
    checkNotFullyCompleted?: boolean
  ) => boolean;
}

export const useAindoFlowAvailability = (): IUseAindoFlowAvailabilityReturn => {
  const { isDoctor } = useActiveProfileContext();
  const { isAindoEnabled } = usePortalConfiguration() ?? {};
  const isAindoAvailable = !!isAindoEnabled && isDoctor;

  const getAindoHeaderExtractionAvailabilityByFile: IUseAindoFlowAvailabilityReturn['getAindoHeaderExtractionAvailabilityByFile'] =
    ({ fileTag, isPdfDataExtractable }: IAindoFile) =>
      !!(isAindoEnabled && fileTag !== FileTag.PATIENT_UNRELATED_DOCUMENT && isPdfDataExtractable);

  const getAindoAvailabilityByFile: IUseAindoFlowAvailabilityReturn['getAindoAvailabilityByFile'] = (
    file: IAindoFile
  ) => isAindoAvailable && getAindoHeaderExtractionAvailabilityByFile(file);

  const getAindoAvailabilityByCompletionPercent: IUseAindoFlowAvailabilityReturn['getAindoAvailabilityByCompletionPercent'] =
    (completionPercent, checkNotFullyCompleted) =>
      isAindoAvailable && completionPercent !== undefined && (checkNotFullyCompleted ? completionPercent < 100 : true);

  return {
    isAindoAvailable,
    getAindoAvailabilityByFile,
    getAindoAvailabilityByCompletionPercent,
    getAindoHeaderExtractionAvailabilityByFile,
  };
};

import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type CanSendPatientInviteVariables = Types.Exact<{
  patientPortalUserId: Types.Scalars['String']['input'];
}>;


export type CanSendPatientInvite = { __typename?: 'Query', canSendPatientInvite: boolean };


export const CanSendPatientInviteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CanSendPatientInvite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canSendPatientInvite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientPortalUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useCanSendPatientInvite__
 *
 * To run a query within a React component, call `useCanSendPatientInvite` and pass it any options that fit your needs.
 * When your component renders, `useCanSendPatientInvite` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSendPatientInvite({
 *   variables: {
 *      patientPortalUserId: // value for 'patientPortalUserId'
 *   },
 * });
 */
export function useCanSendPatientInvite(baseOptions: ApolloReactHooks.QueryHookOptions<CanSendPatientInvite, CanSendPatientInviteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CanSendPatientInvite, CanSendPatientInviteVariables>(CanSendPatientInviteDocument, options);
      }
export function useCanSendPatientInviteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CanSendPatientInvite, CanSendPatientInviteVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CanSendPatientInvite, CanSendPatientInviteVariables>(CanSendPatientInviteDocument, options);
        }
export type CanSendPatientInviteHookResult = ReturnType<typeof useCanSendPatientInvite>;
export type CanSendPatientInviteLazyQueryHookResult = ReturnType<typeof useCanSendPatientInviteLazyQuery>;
export type CanSendPatientInviteQueryResult = Apollo.QueryResult<CanSendPatientInvite, CanSendPatientInviteVariables>;
import { useModalState } from '@lib/react-components';
import { useEffect } from 'react';

import { AiParamsExtractionLoadingModal } from './AiParamsExtractionLoadingModal';
import { AiParamsExtractionTimeoutModal } from './AiParamsExtractionTimeoutModal';
import { ExtractParametersModal, IExtractParametersModalProps } from './ExtractParametersModal';

import { useUploadFilesStore } from 'features/Attachments/components/UploadFiles/hooks';

type AiParamsDataExtractionModalsProps = Omit<IExtractParametersModalProps, 'file'>;

export const AiParamsDataExtractionModals = ({
  onCancel,
  patientId,
}: AiParamsDataExtractionModalsProps): JSX.Element => {
  const [
    isAiParamsExtractionTimeoutModalOpen,
    openAiParamsExtractionTimeoutModal,
    closeAiParamsExtractionTimeoutModal,
  ] = useModalState();
  const [isAiParamsExtractionLoadingModalOpen, , closeAiParamsExtractionLoadingModal] = useModalState(true);
  const { aindoFile } = useUploadFilesStore();

  useEffect(() => {
    if (aindoFile && !aindoFile.hasAnyPdfParametersRevision) {
      onCancel();
    }
  }, [aindoFile]);

  return aindoFile ? (
    <ExtractParametersModal file={aindoFile} patientId={patientId} onCancel={onCancel} />
  ) : (
    <>
      {isAiParamsExtractionLoadingModalOpen && (
        <AiParamsExtractionLoadingModal
          onAiParamsExtractionTimeout={(): void => {
            closeAiParamsExtractionLoadingModal();
            openAiParamsExtractionTimeoutModal();
          }}
        />
      )}
      {isAiParamsExtractionTimeoutModalOpen && (
        <AiParamsExtractionTimeoutModal
          onAiParamsExtractionTimeoutClose={(): void => {
            closeAiParamsExtractionTimeoutModal();
            onCancel();
          }}
        />
      )}
    </>
  );
};

import { CurrentUserContext } from '@lib/core';
import { useAttachResidenceAddress, usePatientsApiValidateGsdHsrResidenceAddressLazyQuery } from '@lib/features-bll';
import { GsdHsrResidenceAddressFormSubmitCallbackProps } from '@lib/features-ui';
import { hasMandatoryAddressDetailsFields, IHiddenValues, useModalState } from '@lib/react-components';
import { omit } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';

import { Maybe } from '__generated__/types';
import { i18n } from 'i18n';
import { useResidenceAddressError } from 'lib/hooks/formErrorsHook';

type UseProfileSettingsModelEditResidenceAddressParams = {
  lastValue?: string | undefined;
  onSuccess: VoidFunction;
};

type UseProfileSettingsModelEditResidenceAddressReturn = {
  textValue: string;
  handleSave: () => Promise<void>;
  addressDetails: Maybe<Omit<IHiddenValues, 'region' | 'placeId'>>;
  residenceAddressErrorText: string;
  handleResidenceAddressChange: (value: string, hiddenValues?: IHiddenValues) => void;
  handleResidenceAddressErrorsBlur: (value: string, hiddenValues?: IHiddenValues) => void;
  handleResidenceAddressDetailsSave: (props: GsdHsrResidenceAddressFormSubmitCallbackProps) => Promise<void>;
  closeResidenceAddressDetailsModal: VoidFunction;
  isResidenceAddressDetailsModalOpened: boolean;
};

type UseProfileSettingsModelEditResidenceAddress = (
  params: UseProfileSettingsModelEditResidenceAddressParams
) => UseProfileSettingsModelEditResidenceAddressReturn;

export const useProfileSettingsModelEditResidenceAddress: UseProfileSettingsModelEditResidenceAddress = ({
  lastValue,
  onSuccess,
}) => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [attachResidenceAddress] = useAttachResidenceAddress();
  const [textValue, setTextValue] = useState<string>('');
  const [addressDetails, setAddressDetails] = useState<Omit<IHiddenValues, 'region' | 'placeId'> | null>(null);
  const [externalGooglePlaceId, setExternalGooglePlaceId] = useState<string>('');
  const { residenceAddressErrorText, handleResidenceAddressErrorsChange, handleResidenceAddressErrorsBlur } =
    useResidenceAddressError();
  const [validateGsdHsrResidenceAddress] = usePatientsApiValidateGsdHsrResidenceAddressLazyQuery();
  const [isResidenceAddressDetailsModalOpened, openResidenceAddressDetailsModal, closeResidenceAddressDetailsModal] =
    useModalState();

  const handleResidenceAddressChange = (value: string, hiddenValues?: IHiddenValues): void => {
    const addressDetailsFields = hiddenValues && omit(hiddenValues, 'region', 'placeId');

    setAddressDetails(null);
    setTextValue(value);
    handleResidenceAddressErrorsChange(value, hiddenValues);

    if (addressDetailsFields && hasMandatoryAddressDetailsFields(addressDetailsFields)) {
      setAddressDetails(addressDetailsFields);
      setExternalGooglePlaceId(hiddenValues?.placeId || '');
    }
  };

  const handleAttachResidenceAddress = async (props?: GsdHsrResidenceAddressFormSubmitCallbackProps): Promise<void> => {
    const { data: addressData } = await attachResidenceAddress({
      variables: {
        externalGooglePlaceId,
        gsdHsrResidenceAddress: props
          ? {
              isoCountryCode: props.residenceAddressCountryCode,
              addressLine: props.residenceAddressLine,
              postcode: props.residenceAddressPostalCode || undefined,
              cityName: props.residenceAddressCityName || undefined,
            }
          : undefined,
      },
    });

    if (addressData) {
      if (currentUser) {
        setCurrentUser({ ...currentUser, currentResidenceAddress: addressData.attachResidenceAddress });
      }

      onSuccess();
    }
  };

  const handleSave = async (): Promise<void> => {
    if (addressDetails) {
      const { data } = await validateGsdHsrResidenceAddress({
        variables: { externalGooglePlaceId, userId: currentUser?.id || '' },
      });

      if (data?.validateGsdHsrResidenceAddress) {
        await handleAttachResidenceAddress();
      } else {
        openResidenceAddressDetailsModal();
      }
    }
  };

  const handleResidenceAddressDetailsSave: UseProfileSettingsModelEditResidenceAddressReturn['handleResidenceAddressDetailsSave'] =
    async props => {
      closeResidenceAddressDetailsModal();
      await handleAttachResidenceAddress(props);
    };

  useEffect(() => {
    setTextValue(lastValue === i18n.t('pages.profileSettings.fields.defaultValues.noData') ? '' : lastValue || '');
  }, [lastValue]);

  return {
    textValue,
    handleSave,
    addressDetails,
    residenceAddressErrorText,
    handleResidenceAddressChange,
    handleResidenceAddressErrorsBlur,
    handleResidenceAddressDetailsSave,
    closeResidenceAddressDetailsModal,
    isResidenceAddressDetailsModalOpened,
  };
};

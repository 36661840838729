import { makeVar, useReactiveVar } from '@apollo/client';

import { Maybe, PDFDataStorageType } from '__generated__/types';
import { IError } from 'authorization/types';
import { IAttachmentFile } from 'graphql/types/files';
import { IPDFData } from 'graphql/types/request';

export interface IChatFile extends IAttachmentFile {
  attachedAt: string;
  threadId?: string;
  type?: PDFDataStorageType;
  data?: IPDFData;
}

export interface IChatFilesStore {
  chatFilesList: Maybe<IChatFile[]>;
  attachmentsLoading: boolean;
  error: Maybe<IError>;
  requestLoading: boolean;
}

export interface IChatFilesStoreActions {
  setAttachmentsLoading: (attachmentsLoading: boolean) => void;
  setChatFilesList: (files: Maybe<IChatFile[]>) => void;
  addChatFile: (file: IChatFile) => void;
  addChatFiles: (files: IChatFile[]) => void;
  removeChatFiles: VoidFunction;
  removeChatFile: (fileId: string) => void;
}

const chatFilesListVar = makeVar<IChatFilesStore['chatFilesList']>(null);
const attachmentsLoadingVar = makeVar<IChatFilesStore['attachmentsLoading']>(false);
const errorVar = makeVar<IChatFilesStore['error']>(null);
const requestLoadingVar = makeVar<IChatFilesStore['requestLoading']>(false);

const setChatFilesList: IChatFilesStoreActions['setChatFilesList'] = files => chatFilesListVar(files);

const setAttachmentsLoading: IChatFilesStoreActions['setAttachmentsLoading'] = attachmentsLoading =>
  attachmentsLoadingVar(attachmentsLoading);

export const addChatFile: IChatFilesStoreActions['addChatFile'] = file =>
  setChatFilesList([...(chatFilesListVar() ?? []), file]);

const addChatFiles: IChatFilesStoreActions['addChatFiles'] = files =>
  setChatFilesList([...(chatFilesListVar() ?? []), ...files]);

const removeChatFiles: IChatFilesStoreActions['removeChatFiles'] = () => setChatFilesList(null);

const removeChatFile: IChatFilesStoreActions['removeChatFile'] = fileId =>
  setChatFilesList(chatFilesListVar() && chatFilesListVar()!.filter(({ file: { id } }) => id !== fileId));

type UseChatModelChatFilesStore = () => IChatFilesStore & IChatFilesStoreActions;

export const useChatModelChatFilesStore: UseChatModelChatFilesStore = () => {
  const chatFilesList = useReactiveVar(chatFilesListVar);
  const attachmentsLoading = useReactiveVar(attachmentsLoadingVar);
  const error = useReactiveVar(errorVar);
  const requestLoading = useReactiveVar(requestLoadingVar);

  return {
    chatFilesList,
    attachmentsLoading,
    error,
    requestLoading,
    setAttachmentsLoading,
    setChatFilesList,
    addChatFile,
    addChatFiles,
    removeChatFiles,
    removeChatFile,
  };
};

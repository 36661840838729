import { useCanSendPatientInvite } from './graphql/__generated__/CanSendPatientInvite.query';
import {
  useCheckPatientExistenceStatus,
  useCheckPatientExistenceStatusLazyQuery,
} from './graphql/__generated__/CheckPatientExistenceStatus.query';
import { useCreatePatient } from './graphql/__generated__/CreatePatient.mutation';
import { useCreatePatientProfileForUser } from './graphql/__generated__/CreatePatientProfileForUser.mutation';
import { useUpdatePatientPersonalInfo } from './graphql/__generated__/UpdatePatientPersonalInfo.mutation';

export const patientApi = {
  useCanSendPatientInvite,
  useCreatePatient,
  useCreatePatientProfileForUser,
  useCheckPatientExistenceStatus,
  useCheckPatientExistenceStatusLazyQuery,
  useUpdatePatientPersonalInfo,
};

export type { CheckPatientExistenceStatus } from './graphql/__generated__/CheckPatientExistenceStatus.query';

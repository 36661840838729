import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorSetPatientUserEmailVariables = Types.Exact<{
  patientPortalUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  patientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  email: Types.Scalars['String']['input'];
}>;


export type DoctorSetPatientUserEmail = { __typename?: 'Mutation', doctorSetPatientUserEmail: { __typename?: 'UserModel', id: string, email?: string | null } };


export const DoctorSetPatientUserEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DoctorSetPatientUserEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"defaultValue":{"kind":"NullValue"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"defaultValue":{"kind":"NullValue"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorSetPatientUserEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientPortalUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}}},{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode;
export type DoctorSetPatientUserEmailMutationFn = Apollo.MutationFunction<DoctorSetPatientUserEmail, DoctorSetPatientUserEmailVariables>;

/**
 * __useDoctorSetPatientUserEmail__
 *
 * To run a mutation, you first call `useDoctorSetPatientUserEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorSetPatientUserEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorSetPatientUserEmail, { data, loading, error }] = useDoctorSetPatientUserEmail({
 *   variables: {
 *      patientPortalUserId: // value for 'patientPortalUserId'
 *      patientId: // value for 'patientId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDoctorSetPatientUserEmail(baseOptions?: ApolloReactHooks.MutationHookOptions<DoctorSetPatientUserEmail, DoctorSetPatientUserEmailVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DoctorSetPatientUserEmail, DoctorSetPatientUserEmailVariables>(DoctorSetPatientUserEmailDocument, options);
      }
export type DoctorSetPatientUserEmailHookResult = ReturnType<typeof useDoctorSetPatientUserEmail>;
export type DoctorSetPatientUserEmailMutationResult = Apollo.MutationResult<DoctorSetPatientUserEmail>;
export type DoctorSetPatientUserEmailMutationOptions = Apollo.BaseMutationOptions<DoctorSetPatientUserEmail, DoctorSetPatientUserEmailVariables>;
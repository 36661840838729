import {
  PrivaciesApiPrivacy,
  privaciesHelperSortRegistrationPrivacies,
  usePrivaciesModelPrivaciesWithUrls,
} from '@lib/features-bll';
import { Loader, StatusCard } from '@lib/react-components';
import { Box } from '@mui/material';

import { RegistrationUiCheckboxesAreaDoctorItems } from './RegistrationUiCheckboxesAreaDoctorItems';
import { RegistrationUiCheckboxesAreaNonDoctorItems } from './RegistrationUiCheckboxesAreaNonDoctorItems';
import { RegistrationUiCheckboxesAreaPatientItems } from './RegistrationUiCheckboxesAreaPatientItems';
import { useStyles } from './styles';

import { Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useRegistrationModelGetProfileTypeWithInviteCheck } from 'features/Registration/model';
import { RegistrationCheckboxesAreaFormikProps } from 'features/Registration/types';
import { i18n } from 'i18n';

interface IRegistrationUiCheckboxesArea {
  privacies?: PrivaciesApiPrivacy[];
  isEhrConsensusDisabled?: boolean;
}

type IRegistrationUiCheckboxesAreaProps = IRegistrationUiCheckboxesArea & RegistrationCheckboxesAreaFormikProps;

export const RegistrationUiCheckboxesArea = ({
  privacies,
  handleChange,
  values,
  errors,
  touched,
}: IRegistrationUiCheckboxesAreaProps): Maybe<JSX.Element> => {
  const { classes: localClasses } = useStyles();

  const {
    isPatient: isPatientProfile,
    isDoctor: isDoctorProfile,
    isNonDoctor: isNonDoctorProfile,
  } = useActiveProfileContext();

  const { isDoctor: isDoctorWithInvite, isNonDoctor: isNonDoctorWithInvite } =
    useRegistrationModelGetProfileTypeWithInviteCheck();

  const isDoctor = isDoctorWithInvite || isDoctorProfile;
  const isNonDoctor = isNonDoctorWithInvite || isNonDoctorProfile;
  const isPatient = (!isDoctor && !isNonDoctor) || isPatientProfile;

  const receivedPrivaciesUrls = usePrivaciesModelPrivaciesWithUrls(privacies || []);

  if (!privacies || !receivedPrivaciesUrls) {
    return <Loader />;
  }

  const {
    termsOfUse,
    ehrConsensus,
    privacyPolicy,
    dataProcessing,
    codeOfMedicalEthics,
    doctorPatientContract,
    newsletterConsensus,
    marketingClientsFirst,
    marketingClientsSecond,
    itsaluteCollaboration,
    itsaluteCollaboration2,
  } = privaciesHelperSortRegistrationPrivacies(receivedPrivaciesUrls);

  return privacies.length ? (
    <Box className={localClasses.checkboxesWrapper}>
      {isDoctor && (
        <RegistrationUiCheckboxesAreaDoctorItems
          values={values}
          errors={errors}
          touched={touched}
          termsOfUse={termsOfUse}
          handleChange={handleChange}
          privacyPolicy={privacyPolicy}
          newsletterConsensus={newsletterConsensus}
          codeOfMedicalEthics={codeOfMedicalEthics}
          doctorPatientContract={doctorPatientContract}
          itsaluteCollaboration={itsaluteCollaboration}
          itsaluteCollaboration2={itsaluteCollaboration2}
        />
      )}
      {isNonDoctor && (
        <RegistrationUiCheckboxesAreaNonDoctorItems
          values={values}
          errors={errors}
          touched={touched}
          termsOfUse={termsOfUse}
          handleChange={handleChange}
          privacyPolicy={privacyPolicy}
          newsletterConsensus={newsletterConsensus}
        />
      )}
      {isPatient && (
        <RegistrationUiCheckboxesAreaPatientItems
          values={values}
          errors={errors}
          touched={touched}
          termsOfUse={termsOfUse}
          ehrConsensus={ehrConsensus}
          handleChange={handleChange}
          privacyPolicy={privacyPolicy}
          dataProcessing={dataProcessing}
          newsletterConsensus={newsletterConsensus}
          doctorPatientContract={doctorPatientContract}
          marketingClientsFirst={marketingClientsFirst}
          marketingClientsSecond={marketingClientsSecond}
        />
      )}
      <StatusCard text={i18n.t('components.forms.registrationForm.hint.requiredDeclarations')} />
    </Box>
  ) : null;
};

import { useContext } from 'react';

import { AttachmentFileModalContext } from '../context';

export const useVerifyIdentityNoveltyModalControls = (): [boolean | undefined, (isModalOpened: boolean) => void] => {
  const { isVerifyIdentityNoveltyModalOpened, setVerifyIdentityNoveltyModalOpened } =
    useContext(AttachmentFileModalContext);

  return [isVerifyIdentityNoveltyModalOpened, setVerifyIdentityNoveltyModalOpened];
};

import { AvatarEntity, PatientSearch } from '@lib/features-bll';
import { ISearchEntity } from '@lib/features-ui';
import { getUserFullName, getUserInitials } from '@lib/utils';

export type PatientSearchItems = PatientSearch['patientList']['items'];

export type PatientSearchItem = PatientSearchItems[number];

type UseSearchEntitiesForPatientSearch = (patients?: PatientSearchItems) => {
  /**
   *  Map of patients from PatientSearch query by portal user id
   */
  patientsMap: Record<string, PatientSearchItem>;
  /**
   *  List of patients prepared for SearchEntities component
   */
  patientsListForSearch: ISearchEntity[];
};

export const useSearchEntitiesForPatientSearch: UseSearchEntitiesForPatientSearch = patients => {
  const patientsMap: Record<string, PatientSearchItem> = {};
  const patientsListForSearch: ISearchEntity[] = [];

  (patients || []).forEach(patient => {
    const { id, user } = patient;

    patientsMap[id] = patient;
    patientsListForSearch.push({
      id,
      name: getUserFullName(user),
      initials: getUserInitials(user),
      avatarEntity: AvatarEntity.PATIENT_ID,
    });
  });

  return { patientsMap, patientsListForSearch };
};

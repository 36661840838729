import { WebToNativeActions } from '@lib/features-bll';

import { createNativeAction, platformBasedAction } from './platform';

interface IDocuments {
  scan: VoidFunction;
  load: VoidFunction;
  foto: VoidFunction;
}

export const documents: IDocuments = {
  scan: (): void => {
    platformBasedAction({
      webAction: () => {},
      nativeAction: createNativeAction(WebToNativeActions.scanDocument),
    });
  },
  load: (): void => {
    platformBasedAction({
      webAction: () => {},
      nativeAction: createNativeAction(WebToNativeActions.loadDocuments),
    });
  },
  foto: (): void => {
    platformBasedAction({
      webAction: () => {},
      nativeAction: createNativeAction(WebToNativeActions.fotoDocument),
    });
  },
};

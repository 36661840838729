import { Loader } from '@lib/react-components';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { routeRequiresAuthentication } from '../../../routes/model';
import { useRootModelOnProfileChange, useRootModelPath } from '../model';

import { NotAuthorizedModal } from './NotAuthorizedModal';

import { clearPersistedData } from 'authorization/helper';
import { useLoginModelLoadUserData } from 'features/Login/model';
import { LoadUserDataErrorCode } from 'features/Login/model/useLoginModelLoadUserData';
import { DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';

interface IAuthorizationProviderProps {
  children: ReactNode;
}

export const RootAuthorizationProvider = ({ children }: IAuthorizationProviderProps): JSX.Element => {
  const { loadUserData } = useLoginModelLoadUserData();
  const { goToPath } = useRootModelPath();
  const { pathname } = useLocation();
  const [loadUserErrorCode, setLoadUserErrorCode] = useState<LoadUserDataErrorCode>();
  const [isLoading, setIsLoading] = useState(true);

  const activeProfileId = useRootModelOnProfileChange();

  const loadUserInfo = async (): Promise<void> => {
    await loadUserData({
      withRedirect: false,
      onError: (errorCode: LoadUserDataErrorCode) => {
        setLoadUserErrorCode(errorCode);

        const [, , ...routeParts] = pathname.split('/');
        const currentRoute = `/${routeParts.join('/')}`;

        if (routeRequiresAuthentication(currentRoute) && errorCode === LoadUserDataErrorCode.NoAuthenticatedUser) {
          clearPersistedData();
          goToPath({ path: DEFAULT_UNAUTHORIZED_ROUTE, searchObj: { redirectTo: pathname } });
        }
      },
    });
    setIsLoading(false);
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const showNotAuthorizedModal = !!loadUserErrorCode && loadUserErrorCode !== LoadUserDataErrorCode.NoAuthenticatedUser;

  const closeNotAuthorizedModal = (): void => {
    setLoadUserErrorCode(undefined);
  };

  return (
    // NOTE this key is here because the profile changes and activates the profileId
    <Loader key={activeProfileId} loading={isLoading} fullScreen>
      {children}
      {showNotAuthorizedModal && (
        <NotAuthorizedModal errorCode={loadUserErrorCode} closeModal={closeNotAuthorizedModal} />
      )}
    </Loader>
  );
};

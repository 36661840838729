import { getCustomPrivacyConfigByPortal, PrivacyUrl } from '@lib/features-bll';
import { Trans } from 'react-i18next';

import { RegistrationUiCheckboxItem } from './RegistrationUiCheckboxItem';
import { useStyles as useCheckboxesAreaStyles } from './styles';

import { PrivacyType } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { RegistrationCheckboxesAreaFormikProps } from 'features/Registration/types';
import { PrivacyPolicyCheckbox } from 'features/Users/components';
import { i18n } from 'i18n';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface IRegistrationUiCheckboxesAreaPatientItems {
  termsOfUse: PrivacyUrl;
  ehrConsensus: PrivacyUrl;
  privacyPolicy: PrivacyUrl;
  dataProcessing: PrivacyUrl;
  newsletterConsensus: PrivacyUrl;
  doctorPatientContract: PrivacyUrl;
  marketingClientsFirst: PrivacyUrl;
  marketingClientsSecond: PrivacyUrl;
  isEhrConsensusDisabled?: boolean;
}

type RegistrationUiCheckboxesAreaPatientItemsProps = IRegistrationUiCheckboxesAreaPatientItems &
  RegistrationCheckboxesAreaFormikProps;

export const RegistrationUiCheckboxesAreaPatientItems = ({
  values,
  errors,
  touched,
  termsOfUse,
  handleChange,
  ehrConsensus,
  privacyPolicy,
  dataProcessing,
  newsletterConsensus,
  doctorPatientContract,
  marketingClientsFirst,
  marketingClientsSecond,
  isEhrConsensusDisabled,
}: RegistrationUiCheckboxesAreaPatientItemsProps): JSX.Element => {
  const portalSlug = usePortalSlug();
  const privacyConfig = getCustomPrivacyConfigByPortal(portalSlug);
  const { classes: checkboxesAreaClasses } = useCheckboxesAreaStyles();

  const getError = getFormErrors(errors, touched);

  return (
    <>
      {termsOfUse.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.TERMS_OF_USE}
          title={i18n.t('components.forms.registrationForm.privacy.rulesOfUse')}
          agreementText={
            <>
              <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.rulesOfUseAgree">
                I declare that I have read and accept the
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={termsOfUse.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.TERMS_OF_USE) &&
            i18n.t('components.forms.registrationForm.validationAdvice.rulesOfUse')
          }
        />
      )}
      {privacyPolicy.enabled && (
        <PrivacyPolicyCheckbox
          link={privacyPolicy.link}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          isPatient
        />
      )}
      {dataProcessing.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.DATA_PROCESSING}
          title={i18n.t('components.forms.registrationForm.privacy.dataProcessingTitle')}
          agreementText={i18n.t('components.forms.registrationForm.privacy.dataProcessingAgree')}
          values={values}
          handleChange={handleChange}
        />
      )}
      {ehrConsensus.enabled && !isEhrConsensusDisabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.EHR_CONSENSUS}
          title={i18n.t('components.forms.registrationForm.label.ehrConsensus')}
          agreementText={i18n.t('components.forms.registrationForm.label.ehrConsensusAgree', {
            context: privacyConfig,
          })}
          values={values}
          handleChange={handleChange}
        />
      )}
      {newsletterConsensus.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.NEWSLETTER_CONSENSUS}
          title={i18n.t('components.forms.registrationForm.privacy.newsletter')}
          agreementText={i18n.t('components.forms.registrationForm.privacy.newsletterAgree')}
          values={values}
          handleChange={handleChange}
        />
      )}
      {marketingClientsFirst.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.MARKETING_CLIENTS_1}
          agreementText={i18n.t('components.forms.registrationForm.privacy.marketingClientsFirst', {
            context: privacyConfig,
          })}
          values={values}
          handleChange={handleChange}
        />
      )}
      {marketingClientsSecond.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.MARKETING_CLIENTS_2}
          agreementText={i18n.t('components.forms.registrationForm.privacy.marketingClientsSecond', {
            context: privacyConfig,
          })}
          values={values}
          handleChange={handleChange}
        />
      )}
      {doctorPatientContract.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.DOCTOR_PATIENT_CONTRACT}
          title={i18n.t('components.forms.registrationForm.privacy.contacts')}
          agreementText={
            <>
              <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.contactsAgreePatient">
                I declare that I have read the medical
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={doctorPatientContract.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Doctor/Patient contract
                </a>
                and accept the conditions reported
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.DOCTOR_PATIENT_CONTRACT) &&
            i18n.t('components.forms.registrationForm.validationAdvice.contacts')
          }
        />
      )}
    </>
  );
};

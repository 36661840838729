import { CurrentUserContext } from '@lib/core';
import { useContext } from 'react';

import { useRootApiSubmitVerifyEmail } from '../api';

import { isError } from 'authorization/types';
import { i18n } from 'i18n';

interface IUseRootModelSubmitVerifyEmailReturn {
  loading: boolean;
  submitVerifyEmailHandler: (
    verificationCode: string,
    onSuccess: VoidFunction,
    onError?: VoidFunction
  ) => Promise<void>;
}

export const useRootModelSubmitVerifyEmail = (): IUseRootModelSubmitVerifyEmailReturn => {
  const { setUserError } = useContext(CurrentUserContext);
  const [submitVerifyEmail, { loading }] = useRootApiSubmitVerifyEmail({
    notifications: {
      onCompleted: i18n.t('success.user.emailVerification'),
      onError: i18n.t('errors.user.emailVerification'),
    },
    onError: error => {
      if (isError(error)) {
        setUserError(error);
      }
    },
  });

  const submitVerifyEmailHandler: IUseRootModelSubmitVerifyEmailReturn['submitVerifyEmailHandler'] = async (
    verificationCode,
    onSuccess,
    onError
  ) => {
    await submitVerifyEmail({
      variables: { verificationCode },
      onCompleted: () => {
        onSuccess();
      },
      onError,
    });
  };

  return { loading, submitVerifyEmailHandler };
};

import { IPatientModalHeaderUser } from '@lib/features-ui';
import { Maybe } from '@lib/utils';
import { createContext } from 'react';

import { PDFDataStorageType } from '__generated__/types';
import { IPDFData } from 'graphql/types/request';

export type PrefilledPDF = IPDFData & {
  fileId?: string;
  threadId?: string;
  type: PDFDataStorageType;
  pdfDataId: string;
  patient?: IPatientModalHeaderUser;
};

interface IAttachmentFileModalContext {
  isVerifyIdentityNoveltyModalOpened?: boolean;
  setVerifyIdentityNoveltyModalOpened: (isModalOpened: boolean) => void;
  isReportModalOpened?: boolean;
  setReportModalOpened: (isModalOpened: boolean) => void;
  isMultipleReportModalOpened?: boolean;
  setDicomMultipleReportModalOpened: (isModalOpened: boolean) => void;
  isDicomMultipleReportModalOpened?: boolean;
  setMultipleReportModalOpened: (isModalOpened: boolean) => void;
  isDicomReportModalOpened?: boolean;
  setDicomReportModalOpened: (isModalOpened: boolean) => void;
  isPrescriptionModalOpened?: boolean;
  setPrescriptionModalOpened: (isModalOpened: boolean) => void;
  isMultidisciplinaryReportModalOpened?: boolean;
  setMultidisciplinaryReportModalOpened: (isModalOpened: boolean) => void;
  prefilledValue: Maybe<PrefilledPDF>;
  setPrefilledValue: (prefilledValue: Maybe<PrefilledPDF>) => void;
  onShownModal?: (el: JSX.Element) => void;
  onCloseModal?: (id: string) => void;
  attachmentsPreviewModals?: Array<JSX.Element>;
  setAttachmentsPreviewModals?: (attachmentsPreviewModals: Array<JSX.Element>) => void;
  setInitialText?: (aiText: string) => void;
  initialText?: string;
  draftMessage?: string;
  setDraftMessage?: (draftMessage: string) => void;
  hasFinishedDocumentCreation: boolean;
  setHasFinishedDocumentCreation: (hasFinished: boolean) => void;
}

const noop: VoidFunction = () => {};

export const AttachmentFileModalContext = createContext<IAttachmentFileModalContext>({
  isVerifyIdentityNoveltyModalOpened: false,
  setVerifyIdentityNoveltyModalOpened: noop,
  isReportModalOpened: false,
  setReportModalOpened: noop,
  isMultipleReportModalOpened: false,
  setMultipleReportModalOpened: noop,
  isDicomMultipleReportModalOpened: false,
  setDicomMultipleReportModalOpened: noop,
  isDicomReportModalOpened: false,
  setDicomReportModalOpened: noop,
  isPrescriptionModalOpened: false,
  setPrescriptionModalOpened: noop,
  isMultidisciplinaryReportModalOpened: false,
  setMultidisciplinaryReportModalOpened: noop,
  prefilledValue: null,
  setPrefilledValue: noop,
  onShownModal: noop,
  onCloseModal: noop,
  attachmentsPreviewModals: [],
  setAttachmentsPreviewModals: noop,
  setInitialText: noop,
  draftMessage: '',
  setDraftMessage: noop,
  initialText: '',
  hasFinishedDocumentCreation: false,
  setHasFinishedDocumentCreation: noop,
});

export enum SidebarItems {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Consultations = 'consultations',
  Intramed = 'intramed',
  Equipes = 'equipes',
  Invites = 'invites',
  Calendar = 'calendar',
  Reminders = 'reminders',
  Info = 'info',
  Invoices = 'invoices',
  Care = 'care',
  HealthRecord = 'healthRecord',
  CarTNetwork = 'carTNetwork',
  Surveys = 'surveys',
  Accounting = 'accounting',
  Clinics = 'clinics',
  PortalDocuments = 'portalDocuments',
  OneToOneChats = 'oneToOneChats',
  ClinicChats = 'clinicChats',
  GlobalSearch = 'globalSearch',
  Referrals = 'referrals',
}

import { MobileMenuModal } from '@lib/react-components';
import { Box, Typography } from '@mui/material';

import { ChatMenuItemType } from 'features/Chat/config';
import { useChatModelMenuItems } from 'features/Chat/model';
import { documents } from 'lib/helpers/documents';

type AddMoreFilesMobileMenuProps = {
  onClose: VoidFunction;
};

export const MobileFileActionsMenu = ({ onClose }: AddMoreFilesMobileMenuProps): JSX.Element => {
  const { items } = useChatModelMenuItems({
    actions: {
      [ChatMenuItemType.ScanDocument]: (): void => {
        documents.scan();
      },
      [ChatMenuItemType.UploadDocuments]: (): void => {
        documents.load();
      },
      [ChatMenuItemType.UploadFoto]: (): void => {
        documents.foto();
      },
    },
  });

  return (
    <MobileMenuModal onCancel={onClose}>
      <Box display="flex" flexDirection="column" ml="35px" gap="16px">
        {items.map(({ title, onClickHandler }, index) => (
          <Box
            key={`${title}-${index}`}
            onClick={(): void => {
              onClose();
              onClickHandler?.();
            }}
          >
            <Typography variant="body1">{title}</Typography>
          </Box>
        ))}
      </Box>
    </MobileMenuModal>
  );
};

import { makeVar, useReactiveVar } from '@apollo/client';

import { Maybe } from '__generated__/types';
import { IProfileType } from 'graphql/types/user';

interface IPatientStoreActions {
  setPatientInstitutionUser: (patientInstitutionUser: IProfileType) => void;
}

interface IPatientStore {
  patientInstitutionUser: Maybe<IProfileType>;
}

type UsePatientStore = () => IPatientStore & IPatientStoreActions;

const patientInstitutionUserVar = makeVar<IPatientStore['patientInstitutionUser']>(null);

const setPatientInstitutionUser: IPatientStoreActions['setPatientInstitutionUser'] = patientInstitutionUserVar;

export const usePatientStore: UsePatientStore = () => {
  const patientInstitutionUser = useReactiveVar(patientInstitutionUserVar);

  return {
    patientInstitutionUser,
    setPatientInstitutionUser,
  };
};

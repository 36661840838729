import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUploadFilesStore } from './useUploadFilesStore';

import { removeAttachmentsToUpload } from 'store/attachments/actionCreators';

interface IUseResetFileListReturn {
  resetFileList: VoidFunction;
}

type UseResetFileList = (additionalReset?: VoidFunction) => IUseResetFileListReturn;

export const useResetFileList: UseResetFileList = additionalReset => {
  const dispatch = useDispatch();
  const { reset } = useUploadFilesStore();

  const resetFileList: IUseResetFileListReturn['resetFileList'] = () => {
    dispatch(removeAttachmentsToUpload());
    reset();
    additionalReset?.();
  };

  useEffect(() => resetFileList, []);

  return { resetFileList };
};

/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { action } from 'typesafe-actions';

import { ActionTypeList } from './types';

import { IIntramedFile } from 'graphql/types/intramed';

export const setAttachmentsLoading = (attachmentsLoading: boolean) =>
  action(ActionTypeList.SET_ATTACHMENTS_LOADING, { attachmentsLoading });

export const addIntramedFile = (file: IIntramedFile) => action(ActionTypeList.ADD_INTRAMED_FILE, { file });

export const addIntramedFiles = (fileList: IIntramedFile[]) => action(ActionTypeList.ADD_INTRAMED_FILES, { fileList });

export const removeIntramedFiles = () => action(ActionTypeList.REMOVE_INTRAMED_FILES);

export const removeIntramedFile = (id: string) => action(ActionTypeList.REMOVE_INTRAMED_FILE, { id });

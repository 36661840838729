import { FileType } from '__generated__/types';
import { IAttachmentFile } from 'graphql/types/files';

export const ALL_TAGS = 'ALL';

// This is the key generated from filterFilesListByTags loop.
// If it changes for any reason this way we should be able to control it by just changing this value
export const NO_TAGS = 'null';

export const getSaveButtonTextEnding = (filesLength: number): string => (filesLength > 1 ? 'files' : 'file');

export const isFileInList = (fileId: string, filesList: string[]): boolean =>
  filesList && filesList.some((value): boolean => value === fileId);

export const getAttachmentsObject = (uploads: IAttachmentFile[], separatedDicoms = true) => {
  const attachments = uploads.filter(
    ({ file: { type } }) =>
      type !== FileType.DICOM && type !== FileType.PRESCRIPTION && type !== FileType.REPORT && type !== FileType.INVOICE
  );

  const dicoms = uploads.filter(({ file: { type } }) => type === FileType.DICOM);
  const prescriptions = uploads.filter(({ file: { type } }) => type === FileType.PRESCRIPTION);
  const reports = uploads.filter(({ file: { type } }) => type === FileType.REPORT);
  const invoices = uploads.filter(({ file: { type } }) => type === FileType.INVOICE);

  if (separatedDicoms) {
    return {
      attachments,
      dicoms,
      prescriptions,
      reports,
      invoices,
    };
  }

  return {
    attachments: [...attachments, ...dicoms],
    prescriptions: [...prescriptions],
    reports: [...reports],
    invoices: [...invoices],
  };
};

export const escapeFile = (file: File) => {
  const escapedName = encodeURIComponent(file.name);
  const newFile = new File([file], escapedName, {
    type: file.type,
  });

  return newFile;
};

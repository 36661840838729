import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type HasDoctorAffiliationsToPromptVariables = Types.Exact<{ [key: string]: never; }>;


export type HasDoctorAffiliationsToPrompt = { __typename?: 'Query', doctorAffiliationsToPrompt: Array<{ __typename?: 'AffiliationForDoctorModel', affiliationId: string }> };


export const HasDoctorAffiliationsToPromptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HasDoctorAffiliationsToPrompt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorAffiliationsToPrompt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affiliationId"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useHasDoctorAffiliationsToPrompt__
 *
 * To run a query within a React component, call `useHasDoctorAffiliationsToPrompt` and pass it any options that fit your needs.
 * When your component renders, `useHasDoctorAffiliationsToPrompt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasDoctorAffiliationsToPrompt({
 *   variables: {
 *   },
 * });
 */
export function useHasDoctorAffiliationsToPrompt(baseOptions?: ApolloReactHooks.QueryHookOptions<HasDoctorAffiliationsToPrompt, HasDoctorAffiliationsToPromptVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<HasDoctorAffiliationsToPrompt, HasDoctorAffiliationsToPromptVariables>(HasDoctorAffiliationsToPromptDocument, options);
      }
export function useHasDoctorAffiliationsToPromptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasDoctorAffiliationsToPrompt, HasDoctorAffiliationsToPromptVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<HasDoctorAffiliationsToPrompt, HasDoctorAffiliationsToPromptVariables>(HasDoctorAffiliationsToPromptDocument, options);
        }
export type HasDoctorAffiliationsToPromptHookResult = ReturnType<typeof useHasDoctorAffiliationsToPrompt>;
export type HasDoctorAffiliationsToPromptLazyQueryHookResult = ReturnType<typeof useHasDoctorAffiliationsToPromptLazyQuery>;
export type HasDoctorAffiliationsToPromptQueryResult = Apollo.QueryResult<HasDoctorAffiliationsToPrompt, HasDoctorAffiliationsToPromptVariables>;
import { CurrentUserContext } from '@lib/core';
import { Loader, useBreakpoints } from '@lib/react-components';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, Suspense, useContext } from 'react';

import { useStyles } from './styles';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { useRootModelRoot } from 'features/Root/model';
import {
  RootHeader,
  RootMobileHeaderProvider,
  RootOnboardingModals,
  RootSideNavigation,
  RootVerifyUserEmail,
} from 'features/Root/ui';
import { useGetVerifyUserNotificationHeight } from 'lib/hooks/useGetVerifyUserNotificationHeight';

interface IAuthorizedRoutesWrapperProps {
  children: ReactNode;
}

export const RootAuthorizedRoutesWrapper = ({ children }: IAuthorizedRoutesWrapperProps): JSX.Element => {
  const { currentUser } = useContext(CurrentUserContext);
  const { isMobileView } = useBreakpoints();
  const { isFullScreen, isMenuCollapsed, needUserVerification, isSideBarHiddenOnMobile, isSideBarHidden } =
    useRootModelRoot();
  const verifyUserNotificationHeight = useGetVerifyUserNotificationHeight();
  const isSsnClinicAvailable = globalSearchDetailsModel.useSsnClinicAvailability();
  const { activeProfile, isIncompleteDoctorProfile } = useActiveProfileContext();

  const withHeader = isMobileView ? !isSideBarHiddenOnMobile : !isSideBarHidden;
  const { classes: localClasses } = useStyles({
    isFullScreen,
    needUserVerification,
    verifyUserNotificationHeight,
    withHeader,
    withSsnClinicLinkVisible: isSsnClinicAvailable,
  });

  if (!activeProfile) {
    return <Loader />;
  }

  return (
    <>
      <RootOnboardingModals />
      {!isSideBarHidden && needUserVerification && !currentUser?.isEmailVerified && <RootVerifyUserEmail />}
      {withHeader && (
        <Box displayPrint="none">
          {!isIncompleteDoctorProfile && <RootSideNavigation />}
          {currentUser && (
            <RootMobileHeaderProvider>
              <RootHeader
                needUserVerification={needUserVerification}
                isFullScreen={isFullScreen}
                isMenuCollapsed={isMenuCollapsed}
                userInfo={currentUser}
              />
            </RootMobileHeaderProvider>
          )}
        </Box>
      )}
      <Box
        className={clsx(
          isMobileView ? localClasses.rootWrapperMobile : localClasses.rootWrapper,
          !isSideBarHidden && localClasses.rootWrapperDesktop,
          isSideBarHiddenOnMobile && localClasses.rootWrapperFull,
          !isSideBarHidden &&
            !isFullScreen &&
            !isIncompleteDoctorProfile &&
            (isMenuCollapsed ? localClasses.sidebarCollapsed : localClasses.sidebarExpanded),
          isFullScreen ? localClasses.fullScreen : localClasses.sideBarTransition,
          isIncompleteDoctorProfile && localClasses.incompleteDoctorProfile
        )}
      >
        <Suspense fallback={null}>{children}</Suspense>
      </Box>
    </>
  );
};

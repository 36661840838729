import { NotificationType, useNotifications } from '@lib/core';
import { AttachPatientFile, AttachPatientFileVariables } from '@lib/features-bll';
import { useDispatch } from 'react-redux';

import { attachmentsApi } from '../api';

import { IAttachmentFile } from 'graphql/types/files';
import { i18n } from 'i18n';
import { updatePatientFileList } from 'store/patient/actionCreators';

interface IUseAttachPatientFileReturnParams {
  variables: AttachPatientFileVariables;
}

export type UseAttachPatientFileReturn = (params: IUseAttachPatientFileReturnParams) => void;

// TODO: remove when cleaning medical data queries
const fixAttachmentTyping = (data: AttachPatientFile['attachPatientFile']): IAttachmentFile =>
  data as unknown as IAttachmentFile;

export const useAttachPatientFile = (): UseAttachPatientFileReturn => {
  const { showNotification } = useNotifications();
  const dispatch = useDispatch();

  const [attachPatientFileMutation] = attachmentsApi.useAttachPatientFile({
    onCompleted: (data): void => {
      showNotification(i18n.t('features.attachments.uploadFiles.successUpload'), NotificationType.SUCCESS);
      dispatch(updatePatientFileList(fixAttachmentTyping(data.attachPatientFile)));
    },
  });

  return attachPatientFileMutation;
};

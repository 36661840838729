import isNil from 'lodash-es/isNil';
import { useEffect, useState } from 'react';

import { IuseUploadFileItemStateReturn, useUploadFileItemState } from './useUploadFileItemState';

import { FileTag, Maybe } from '__generated__/types';
import { useMandatoryFieldError } from 'lib/hooks/formErrorsHook';

interface IUseUploadFileItemInitialValues {
  fileName: string;
  dateOfExecution: Maybe<Date>;
  fileTag?: string;
  isFilePrivate?: boolean;
}

interface IUseUploadFileItem {
  index: number;
  initialValues: IUseUploadFileItemInitialValues;
}

interface IUseUploadFileItemReturn {
  fileName: string;
  fileDateOfExecution: Maybe<Date>;
  fileTag?: string;
  isFilePrivate?: boolean;
  handleFileNameChange: IuseUploadFileItemStateReturn['commitFileName'];
  handleFileTagChange: IuseUploadFileItemStateReturn['commitFileTag'];
  handleFileDateOfExecutionChange: IuseUploadFileItemStateReturn['commitFileDateOfExecution'];
  handleIsFilePrivateChange: IuseUploadFileItemStateReturn['commitIsFilePrivate'];
  removeFile: IuseUploadFileItemStateReturn['removeFile'];
  fileNameErrorText: string;
  fileTagErrorText: string;
  fileDateOfExecutionErrorText: string;
}

export const useUploadFileItem = ({ index, initialValues }: IUseUploadFileItem): IUseUploadFileItemReturn => {
  const { errorText: fileNameErrorText, handleFieldChange: handleFileNameChangeMandatoryCheck } =
    useMandatoryFieldError();
  const { errorText: fileTagErrorText, handleFieldChange: handleFileTagChangeMandatoryCheck } =
    useMandatoryFieldError();
  const { errorText: fileDateOfExecutionErrorText, handleFieldChange: handleDateOfExecutionChangeMandatoryCheck } =
    useMandatoryFieldError();

  const { commitFileName, commitFileTag, commitIsFilePrivate, commitFileDateOfExecution, removeFile } =
    useUploadFileItemState({ index });

  const [fileName, setFileName] = useState<string>(initialValues.fileName);
  const [fileDateOfExecution, setFileDateOfExecution] = useState<Maybe<Date>>(initialValues.dateOfExecution);
  const [fileTag, setFileTag] = useState<string | undefined>(initialValues.fileTag);
  const [isFilePrivate, setIsFilePrivate] = useState<boolean | undefined>(initialValues.isFilePrivate);

  const handleFileNameChange: IUseUploadFileItemReturn['handleFileNameChange'] = value => {
    commitFileName(value);
    setFileName(value);
    handleFileNameChangeMandatoryCheck(value);
  };

  const handleFileTagChange: IUseUploadFileItemReturn['handleFileTagChange'] = value => {
    commitFileTag(value);
    setFileTag(value);
    handleFileTagChangeMandatoryCheck(value);
  };

  const handleFileDateOfExecutionChange: IUseUploadFileItemReturn['handleFileDateOfExecutionChange'] = value => {
    commitFileDateOfExecution(value);
    setFileDateOfExecution(value);
    handleDateOfExecutionChangeMandatoryCheck(value?.toString() ?? '');
  };

  const handleIsFilePrivateChange: IUseUploadFileItemReturn['handleIsFilePrivateChange'] = value => {
    commitIsFilePrivate(value);
    setIsFilePrivate(value);
  };

  useEffect(() => {
    commitFileName(fileName);
    commitFileDateOfExecution(fileDateOfExecution);
    commitFileTag(fileTag as FileTag);
    if (!isNil(isFilePrivate)) commitIsFilePrivate(isFilePrivate);
  }, []);

  return {
    fileName,
    fileDateOfExecution,
    fileTag,
    isFilePrivate,
    handleFileNameChange,
    handleFileTagChange,
    handleFileDateOfExecutionChange,
    handleIsFilePrivateChange,
    removeFile,
    fileNameErrorText,
    fileTagErrorText,
    fileDateOfExecutionErrorText,
  };
};

import { patientApi } from 'features/Patient/api';
import { usePortalConfiguration } from 'features/Portal';

interface IUseInvitePatientCardVisibilityProps {
  patientPortalUserId?: string;
  isFullyRegistered: boolean;
}

type UseInvitePatientCardVisibility = (props: IUseInvitePatientCardVisibilityProps) => boolean;

export const useInvitePatientCardVisibility: UseInvitePatientCardVisibility = ({
  patientPortalUserId,
  isFullyRegistered,
}) => {
  const configuration = usePortalConfiguration();

  const { data } = patientApi.useCanSendPatientInvite({
    variables: {
      patientPortalUserId: patientPortalUserId!,
    },
    skip: !patientPortalUserId,
  });

  return !isFullyRegistered && !!configuration?.isPatientSelfRegistrationEnabled && !!data?.canSendPatientInvite;
};

import { BREAK_POINTS } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  modal: {
    padding: '48px 64px 24px',
    borderRadius: 10,
    maxWidth: 650,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: '0',
    },
  },

  modalContent: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: '20px 0',
    },
  },
}));

import { useNotifications } from '@lib/core';
import { useGetPatientLazyQuery } from '@lib/features-bll';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { setNoEhrPrivacies } from 'store/patient/actionCreators';
import { getMedicalDataByPatientAction } from 'store/patient/actions';

type FetchPatientMedicalData = (avoidCache?: boolean) => Promise<void>;
type UseFetchMedicalData = (props?: { withLoader?: boolean }) => FetchPatientMedicalData;

export const useFetchMedicalData: UseFetchMedicalData = ({ withLoader } = { withLoader: true }) => {
  const dispatch = useDispatch();
  const { isPatient, activePatient } = useActiveProfileContext();
  const { showNotification } = useNotifications();
  const {
    params: { patientId },
  } = useRouteMatch<{ patientId: string }>();

  const currentPatientId = patientId || activePatient?.id || '';

  const [getPatient] = useGetPatientLazyQuery({
    variables: {
      patientId: currentPatientId,
    },
    fetchPolicy: 'cache-first',
  });

  return async (avoidCache?: boolean): Promise<void> => {
    let patientEhrConsensus: boolean | undefined;

    if (currentPatientId && !isPatient) {
      const { data } = await getPatient({
        variables: {
          patientId: currentPatientId,
        },
        fetchPolicy: avoidCache ? 'network-only' : 'cache-first',
      });

      patientEhrConsensus = data?.patient.privacyAcceptance.ehrConsensus;
    }

    dispatch(
      getMedicalDataByPatientAction(
        { patientId: currentPatientId },
        {
          onSuccess: () => {
            dispatch(setNoEhrPrivacies(!isPatient && !patientEhrConsensus));
          },
          showNotification,
        },
        withLoader
      )
    );
  };
};

import { AssistantDocIcon, AssistantIcon, MaterialSymbolsIcon } from '@lib/icons';
import { Modal } from '@lib/react-components';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import { useStyles } from './styles';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { i18n } from 'i18n';

export const AiLoadingModal = (): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { palette } = useTheme();
  const { isPatient } = useActiveProfileContext();

  const Icon = isPatient ? AssistantIcon : AssistantDocIcon;
  const iconSize = isPatient ? 45 : 30;

  return (
    <Modal
      classes={{ paper: localClasses.modal, content: localClasses.modalContent }}
      isDraggable={false}
      hideModalHeader
    >
      <Stack>
        <Typography variant="h3" textAlign="center">
          {i18n.t('features.attachments.uploadFiles.aiLoadingModal.info')}
        </Typography>
        <Stack sx={{ position: 'relative', justifyContent: 'center', alignItems: 'center', margin: '32px 0 24px 0' }}>
          <CircularProgress size={115} />
          <Stack sx={{ position: 'absolute', borderRadius: '50%', bgcolor: palette.surface.default, padding: '8px' }}>
            <MaterialSymbolsIcon icon={<Icon width={iconSize} height={iconSize} fill={palette.action.selected} />} />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
